import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { ActionDropdown, BrandAndBranchUserForm } from '@shared/components';
import { BrandOrBranch, LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { BrandUserModel, PaginateModel, PaginationQuery } from '@shared/models';
import { Button, Drawer, Spin, Table, TableProps, notification } from 'antd';
import { useEffect, useState } from 'react';

type BrandUsersProps = {
  users: PaginateModel<BrandUserModel> | undefined;
  onSave: (data: BrandUserModel, value: boolean) => void;
  onDelete: (id: string) => void;
  loading: boolean;
  enableProductTypes: string[];
  paginationQuery: PaginationQuery;
  onChangePage: (currentPage: number, pageSize: number) => void;
};

export const BrandUsers = ({ users, enableProductTypes, onSave, onDelete, loading, paginationQuery, onChangePage }: BrandUsersProps) => {
  useAccessableSeachParams();
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  const columns: TableProps<BrandUserModel>['columns'] = [
    {
      title: 'Ad',
      dataIndex: 'name',
      key: 'name',
      className: 'text-center',
    },
    {
      title: 'Soyad',
      dataIndex: 'surname',
      key: 'surname',
      className: 'text-center',
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'phone',
      className: 'text-center',

      render: (_, record: BrandUserModel) => {
        return (
          <>
            {record.phone?.code + ' '}
            {record.phone?.number}
          </>
        );
      },
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      key: 'email',
      className: 'text-center',
    },
    {
      render: (_, record) => (
        <div className="text-center">
          <ActionDropdown
            data={record}
            onDelete={async () => {
              onDelete(record.id!);
              onClose();
            }}
            onEdit={(data) => {
              setOpen(true);
              setSelectedUser(data);
              setIsUpdateMode(true);
            }}
          />
        </div>
      ),
    },
  ];

  //Drawer show
  const showDrawer = () => {
    setOpen(true);
    setIsUpdateMode(false);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedUser({});
    setIsUpdateMode(false);
  };

  const title = (
    <div className="flex justify-between">
      <div></div>
      <Button type="dashed" icon={<PlusCircleOutlined />} onClick={showDrawer}>
        Kullanıcı ekle
      </Button>
    </div>
  );

  return (
    <>
      <div className="flex justify-end">
        <Drawer title={isUpdateMode ? 'Kullanıcı Güncelle' : 'Kullanıcı Ekle'} onClose={onClose} open={open}>
          <BrandAndBranchUserForm
            user={selectedUser}
            brandOrBranch={BrandOrBranch.BRAND}
            enableProductTypes={enableProductTypes}
            onSave={async (data) => {
              onSave(data, isUpdateMode);
              onClose();
            }}
          />
        </Drawer>
      </div>

      <Table
        loading={loading}
        rowKey="id"
        pagination={{ showSizeChanger: true, showPrevNextJumpers: true, total: users?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        onChange={(pagination) => onChangePage(pagination.current!, pagination.pageSize!)}
        columns={columns}
        dataSource={users?.data ?? []}
        bordered
        title={() => title}
      />
    </>
  );
};

BrandUsers.path = '/brand_manager/brand_users';
BrandUsers.title = 'Marka Kullanıcıları';
BrandUsers.group = 'flat';
BrandUsers.roles = [Role.ADMIN];
BrandUsers.layouts = [LayoutEnums.BRANDMANAGER];
BrandUsers.icon = <UserOutlined size={20} />;
