import { ImageUpload, SoundUpload } from "@shared/components";
import { CdnScope } from "@shared/components/curriculum/FileUpload";
import { CourseTopicPageType } from "@shared/constants";
import { CourseTopicPageModel, MediaModel } from "@shared/models"
import { Typography } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill-new";

type CreateImagePageProps = {
    page: CourseTopicPageModel,
    onChange: (page: CourseTopicPageModel) => void,
    cdnScope:CdnScope,
}

export const CreateImagePage = ({ page, onChange, cdnScope }: CreateImagePageProps) => {
    const [image, setImage] = useState<MediaModel>();
    const [text, setText] = useState<string>();
    const [sound, setSound] = useState<MediaModel>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setImage(page.content?.image);
        setText(page.content?.text);
        setSound(page.content?.sound);
    }, [page.id]);

    useEffect(() => {
        if (image || text || sound) {
            const i = {
                ...page,
                content: {
                    ...page.content,
                    image,
                    text,
                    sound
                },
                type: CourseTopicPageType.IMAGE,

            } as CourseTopicPageModel;
            onChange(i);
        }

    }, [image, text, sound]);

    return (<div className="flex flex-col space-y-2">
        <div className="grid grid-cols-2">
            <div className="flex flex-col items-start justify-between flex-wrap">
                <Typography.Text type="secondary" >
                    <span className="text-red-500 text-wrap">*</span> Desteklenen formatlar: .mp4, .oog, .webm
                </Typography.Text>
                <ImageUpload
                    image={{
                        id: page.content?.image?.resourceId,
                        variants: [...(page.content?.image?.resourceUrl ? [page.content?.image?.resourceUrl]! : [])]
                    }}
                    onChange={
                        (i) => {
                            setImage({
                                resourceId: i.id,
                                resourceUrl: i.variants![0]
                            });
                        }
                    }
                    cdnScope={cdnScope} 
                    onChangeLoading={setIsLoading}
                    disabled={isLoading}
                    isBigImage = {true}
                    />

            </div>
            <div className="flex flex-col items-start justify-between flex-wrap">
                <Typography.Text type="secondary" >
                 Desteklenen formatlar: .mpeg, .wav (Opsiyonel)
                </Typography.Text>
                <SoundUpload  sound={sound} onChange={setSound} cdnScope={cdnScope} onChangeLoading={setIsLoading} disabled={isLoading}/>
            </div>
        </div>
        <div className="flex flex-col justify-start space-y-1">
            <span>Zorunlu olmayan metinalanı</span>
            <div className="h-60 bg-white">
                <ReactQuill
                    value={text}
                    onChange={(t) => {
                        setText(t);
                    }}
                    className="h-full p-0 m-0"
                    theme="snow" 
                    readOnly={isLoading}
                    />
            </div>
        </div>

    </div>)
}