import { CourseTopicType } from "@shared/constants"
import { CourseTopicPageModel } from "./course_topic_page"

export type ContentVersionNavigation = {
    page: CourseTopicPageModel,
    tree: ContentUnitTree[],
    meta: ContentMeta,
}

export type ContentUnitTree = {
    approved: boolean,
    id: string,
    name: string,
    description: string,
    percent: number,
    topics: ContentTopicTree[],
}

export type ContentTopicTree = {
    approved: boolean,
    id: string,
    name: string,
    description: string,
    pages: ContentPageTree[],
    percent: number,
    type: CourseTopicType
}

export type ContentPageTree = {
    approved: boolean,
    id: string,
    order: number,
}

export type ContentUnitMeta = {
    id: string,
    name: string,
}
export type ContentTopicMeta = {
    id: string,
    name: string,
}

export type ContentMeta = {
    current: number,
    total: number,
    hasNext: boolean,
    hasPrev: boolean,
    pageId: string,
    topic: ContentTopicMeta,
    unit: ContentUnitMeta
}

