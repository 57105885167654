import { DownOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { LocalStorageKeys } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { Button, Dropdown, MenuProps } from 'antd';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const bc = new BroadcastChannel('auth.channel.joedtech');
type HeaderDropDownProps = {
  profilUrl?: string;
  settingUrl?: string;
  bgColor?: string;
  textColor?: string;
  bgOpacity?: string;
};
export const HeaderDropDown = ({ profilUrl, settingUrl, bgColor = '', bgOpacity = '', textColor = '' }: HeaderDropDownProps) => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const items: MenuProps['items'] = [
    {
      label: 'Profilim',
      key: '1',
      icon: <UserOutlined />,
      onClick: () => {
        if (profilUrl) {
          navigate(profilUrl + location.search);
        }
      },
    },
    {
      label: 'Ayarlar ve Bildirim',
      key: '2',
      icon: <SettingOutlined />,
      onClick: () => {
        if (settingUrl) {
          navigate(settingUrl + location.search);
        }
      },
    },
    {
      label: 'Çıkış Yap',
      key: '3',
      icon: <LogoutOutlined />,
      danger: true,
      onClick: () => logout(),
    },
  ];
  const logout = useCallback(() => {
    localStorage.removeItem(LocalStorageKeys.USER);
    localStorage.removeItem(LocalStorageKeys.THEN);
    localStorage.removeItem(LocalStorageKeys.LAST);
    localStorage.removeItem('i18nextLng');

    bc.postMessage({ type: 'LOGOUT' });

    window.location.reload();
  }, []);
  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    //TODO buraya gelecek sayfa yönlendirmeleri
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <Dropdown menu={menuProps} className={`flex ml-1 p-4 items-center justify-center`}>
      <Button size="small" style={{ backgroundColor: bgColor + bgOpacity }}>
        <span className="text-sm" style={{ color: textColor }}>
          {user.name} {user.surname}
        </span>
        <DownOutlined style={{ color: textColor }} />
      </Button>
    </Dropdown>
  );
};
