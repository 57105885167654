import { VideoUpload } from "@shared/components";
import { CdnScope } from "@shared/components/curriculum/FileUpload";
import { CourseTopicPageType } from "@shared/constants";
import { CourseTopicPageModel, MediaModel } from "@shared/models";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill-new";

type CreateVideoPageProps = {
    page: CourseTopicPageModel,
    onChange: (page: CourseTopicPageModel) => void,
    cdnScope: CdnScope
}

export const CreateVideoPage = ({ page, onChange, cdnScope }: CreateVideoPageProps) => {
    const [video, setVideo] = useState<MediaModel>();
    const [text, setText] = useState<string>();


    useEffect(() => {
        setVideo(page.content?.video);
    }, [page.id]);

    useEffect(() => {
        if (video || text) {
            const i = {
                ...page,
                content: {
                    video: video,
                    text: text,
                },
                type: CourseTopicPageType.VIDEO,
            } as CourseTopicPageModel;
            onChange(i);
        }
    }, [video, text]);

    return (
        <div className="flex flex-col space-y-2">
            <div className="flex flex-col items-start justify-between flex-wrap">
                <Typography.Text type="secondary" >
                    <span className="text-red-500 text-wrap">*</span> Desteklenen formatlar: .mp4, .oog, .webm
                </Typography.Text>
                <VideoUpload
                    video={video}
                    onChange={(video) => {
                        setVideo(video);
                    }}
                    onChangeLoading={(b) => {
                        //TODO upload işlemlerinde loading çalışır olamlı
                    }}
                    cdnScope={cdnScope}
                />

            </div>
            <div className="flex flex-col justify-start space-y-1">
                <span>Zorunlu olmayan metinalanı</span>
                <div className="h-60 bg-white">
                    <ReactQuill
                        value={text}
                        onChange={(t) => {
                            setText(t);
                        }}
                        className="h-full p-0 m-0"
                        theme="snow" />
                </div>
            </div>

        </div>
    );
}