import { LocalStorageKeys } from "@shared/constants";
import { Switch } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type TextPageProps = {
    text: string,
}
export const TextPage = ({ text }: TextPageProps) => {

const [searchParams] = useSearchParams();


    useEffect(() => {
        const div = document.getElementsByClassName('content_text') as HTMLCollectionOf<HTMLElement>;
        if (div && div.length > 0) {
            div[0].style.fontSize = `${searchParams.get("fontSize") ?? 1.1}rem`;
        }
    }, [searchParams.get("fontSize")]);


    useEffect(() => {

        const contentTextHtlmList = document.getElementsByClassName("content_text");
        for (let i = 0; i < contentTextHtlmList.length; i++) {
            let d = contentTextHtlmList[i];
            const html = d.innerHTML;
            const hiddenHtml = html.replace(/\[\?([^\]]+)\]/g, '<button class="reveal-btn" data-text="$1">Cevabı Göster</button>');
            d.innerHTML = hiddenHtml;
        }

        const buttons = Array.from(document.getElementsByClassName("reveal-btn"));

        if (buttons.length != 0) {
            buttons.forEach((btn) => {
                btn.addEventListener('click', () => {
                    //btn.innerHTML = btn.getAttribute("data-text")!;
                    const originalText = btn.getAttribute("data-text")!;
                    btn.outerHTML = `<span class="revealed-text"><strong>${originalText}</strong></span>`;
                });
            });

            const showAllButton = document.getElementById("show-all-btn");
            showAllButton?.addEventListener('click', () => {
                const remainingButtons = Array.from(document.getElementsByClassName("reveal-btn"));
                remainingButtons.forEach((btn) => {
                    const originalText = btn.getAttribute("data-text")!;
                    btn.outerHTML = `<span class="revealed-text"><strong>${originalText}</strong></span>`;
                });
            });
        } else {
            const showAllButton = document.getElementById("show-all-btn");
            showAllButton?.remove();
        }



    }, [text]);
    return (
        <>
            {
                <style>{`
                .content_text {
                    font-size: 1.1rem;
                }
                .reveal-btn {
                    background-color: #007bff;
                    color: white;
                    border: none;
                    padding: 3px 3px 3px 3px;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .reveal-btn:hover {
                    background-color: #0056b3;
                }
                .revealed-text {
                    font-weight: bold;
                    color: black;
                }
                .show-all-btn {
                    background-color: #28a745;
                    color: white;
                    border: none;
                    padding: 5px 10px;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .show-all-btn:hover {
                    background-color: #218838;
                }
            `}</style>
            }
            <div className="flex flex-col h-full w-full ">
                <div className="content_text h-full w-full overflow-y-auto flex flex-col justify-center" dangerouslySetInnerHTML={{ __html: text || '' }} />
                <div className="flex justify-end">
                    <button id="show-all-btn" className="show-all-btn">Tüm Cevapları Göster</button>
                </div>
            </div>
        </>
    );
}