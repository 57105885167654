import { BellOutlined } from '@ant-design/icons';
import { UserInformationComponent } from '@shared/components';
import { useUserNotificationContext } from '@shared/contexts';
import { useWindowSize } from '@shared/hooks';
import { Menu as AntMenu, Badge, Button, Image, Layout, Popover } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { ReactElement } from 'react';

const { Header } = Layout;

type HeaderComponentProps = {
  logoSrc: string;
  appNavigationView: ReactElement;
  menuItems?: ItemType<MenuItemType>[];
  openKeys?: string[];
  selectedKeys?: string[];
  headerBackground?: string;
  appNavigationViewBackgroundColor: string;
  onMenuOpenChange?: (openKeys: string[]) => void;
  headerDropDown: ReactElement;
  isNotifications?: boolean;
  notificationIconColor?: string;
};
export const HeaderComponent = ({
  logoSrc,
  appNavigationView,
  menuItems = [],
  openKeys,
  selectedKeys,
  headerBackground = 'white',
  appNavigationViewBackgroundColor,
  onMenuOpenChange,
  headerDropDown,
  isNotifications = true,
  notificationIconColor
}: HeaderComponentProps) => {
  const { unreadCount } = useUserNotificationContext();
  return (
    <Header style={{ display: 'flex', alignItems: 'center', backgroundColor: headerBackground, lineHeight: '0' }} className="rounded-b-[20px] p-4  ">
      {logoSrc && <Image preview={false} src={logoSrc} />}
      {appNavigationView && (
        <div className="ml-4 rounded-xl" style={{ background: appNavigationViewBackgroundColor }}>
          {appNavigationView}
        </div>
      )}
      {menuItems && (
        <AntMenu
          mode="horizontal"
          items={menuItems}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onOpenChange={onMenuOpenChange}
          style={{ flex: 1, backgroundColor: headerBackground, borderBottom: 'none' }}
          className="header-menu items-center justify-center"

        />
      )}
      <div className="flex flex-row justify-between">
        {isNotifications ? (
          <div>
            <Popover
              content={
                <div className="w-[500]">
                  <UserInformationComponent />
                </div>
              }
              trigger={'click'}
              placement="bottomLeft"
              onOpenChange={(visible) => {}}
            >
              <Badge count={unreadCount} overflowCount={99} offset={[-10, 9]} size='small'>
                <Button type="text" icon={<BellOutlined style={{ fontSize: '25px' ,color:notificationIconColor}}  />} />
              </Badge>
            </Popover>
          </div>
        ) : (
          <></>
        )}
        {headerDropDown && <div className="ml-4">{headerDropDown}</div>}
      </div>
    </Header>
  );
};
