import { BrandOrBranch, ProductPermissionValues, ProductsEnum, Role } from '@shared/constants';
import { CascaderOption, PermissionsModel } from '@shared/models';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { MultipleCascader } from './MultipleCascader';
import i18n from '@shared/localization/i18n';

type RoleAndPermissonsProps = {
  permission: PermissionsModel;
  enableProductTypes: string[];
  brandOrBranch: BrandOrBranch;
  permissionsFeatureValue?: (string | number)[][];
  onChange: (permission: PermissionsModel) => void;
};

//TODO verileri oluşturan fonksiyonların yapısı değiştirilebilir
export const RoleAndPermissons = ({ permission = {}, enableProductTypes, brandOrBranch, permissionsFeatureValue, onChange }: RoleAndPermissonsProps) => {
  const [multipleCascaderDisabled, setMultipleCascaderDisabled] = useState<boolean>(false);
  const [multipleCascaderValue, setMultipleCascaderValue] = useState<(string | number)[][]>();
  const [cascaderItems, setCasCaderItems] = useState<CascaderOption[]>([]);
  const [role, setRole] = useState<Role>();

  useEffect(() => {
    if (Object.keys(permission).length == 0 && multipleCascaderValue?.length != 0) {
      setMultipleCascaderValue([]);
    } else if (cascaderItems.length > 0 && permission.values) {
      const item: string[][] = [];
      Object.keys(ProductPermissionValues[brandOrBranch]).forEach((key) => {
        Object.keys(ProductPermissionValues[brandOrBranch][key]).forEach((k) => {
          if (typeof ProductPermissionValues[brandOrBranch][key][k] == 'string') {
            if (permission.values!.includes(ProductPermissionValues[brandOrBranch][key][k])) {
              item.push([key, ProductPermissionValues[brandOrBranch][key][k]]);
            }
          } else {
            Object.keys(ProductPermissionValues[brandOrBranch][key][k]).forEach((t) => {
              if (permission.values!.includes(ProductPermissionValues[brandOrBranch][key][k][t])) {
                item.push([key, k, ProductPermissionValues[brandOrBranch][key][k][t]]);
              }
            });
          }
        });
      });
      setMultipleCascaderValue(item);
    }
  }, [cascaderItems, permission]);

  useEffect(() => {
    const productsString = Object.values(ProductsEnum) as string[];
    const items = Object.keys(ProductPermissionValues[brandOrBranch])
      .map((key) => {
        if (productsString.includes(key)) {
          if (enableProductTypes.includes(key)) {
            return {
              value: key,
              label: key,
              children: Object.keys(ProductPermissionValues[brandOrBranch][key]).map((k) => {
                if (typeof ProductPermissionValues[brandOrBranch][key][k] == 'object') {
                  return {
                    value: k,
                    label: k,
                    children: Object.keys(ProductPermissionValues[brandOrBranch][key][k]).map((t) => {
                      return {
                        value: ProductPermissionValues[brandOrBranch][key][k][t],
                        label: t,
                      } as CascaderOption;
                    }) as CascaderOption[],
                  } as CascaderOption;
                } else {
                  return {
                    value: ProductPermissionValues[brandOrBranch][key][k],
                    label: k,
                  } as CascaderOption;
                }
              }) as CascaderOption[],
            } as CascaderOption;
          }
        } else {
          return {
            value: key,
            label: key,
            children: Object.keys(ProductPermissionValues[brandOrBranch][key]).map((k) => {
              if (typeof ProductPermissionValues[brandOrBranch][key][k] == 'object') {
                return {
                  value: k,
                  label: k,
                  children: Object.keys(ProductPermissionValues[brandOrBranch][key][k]).map((t) => {
                    return {
                      value: ProductPermissionValues[brandOrBranch][key][k][t],
                      label: t,
                    } as CascaderOption;
                  }) as CascaderOption[],
                } as CascaderOption;
              } else {
                return {
                  value: ProductPermissionValues[brandOrBranch][key][k],
                  label: k,
                } as CascaderOption;
              }
            }) as CascaderOption[],
          } as CascaderOption;
        }
      })
      .filter((m) => {
        if (m != undefined) {
          return m;
        }
      }) as CascaderOption[];
    setCasCaderItems(items);
  }, [enableProductTypes, brandOrBranch]);

  /*const adminValueCalculater = (item: CascaderOption[]) => {
        const values: string[] = [];

        function findValue(items: CascaderOption[]) {
            items.forEach((i) => {
                if(i.children){
                    findValue(i.children);
                }else{
                    values.push(i.value!.toString());
                }
            });
        }
        findValue(item);

        return values;
    }*/

  const selectAdminRole = () => {
    const permission = {} as PermissionsModel;
    const values: string[] = [];

    /*const brandPermissions = ProductPermissionValues[brandOrBranch];
        const multipleArray: (string | number)[][] = [];
        enableProductTypes.forEach((type) => {
            const product: string = type;
            multipleArray.push([product]);
            const findProduct = products.find((p) => (p == product));
            if (!findProduct) {
                products.push(product);
            }
            const modules = brandPermissions[product];
            (Object.values(modules) as object[]).forEach((m) => {
                (Object.values(m) as string[]).forEach((f) => {
                    const value: string = f;
                    const findValue = values.find((v) => (v == value));
                    if (!findValue) {
                        values.push(value);
                    }
                });
            });
        });*/

    //setMultipleCascaderValue([]);
    permission['role'] = Role.ADMIN;
    permission['products'] = enableProductTypes;
    permission['values'] = values;
    onChange(permission);
  };

  const multipleCascaderChange = (v) => {
    //setMultipleCascaderValue(v);
    const permission = {} as PermissionsModel;
    const products: string[] = [];
    const values: string[] = [];
    v.forEach((item: string[]) => {
      if (enableProductTypes.includes(item[0]) && !products.includes(item[0])) {
        products.push(item[0]);
      }
      values.push(item[item.length - 1]);
    });
    permission['products'] = products;
    permission['values'] = values;
    //TODO kullanıcı user seçsede tüm yetkileri eliyle seçtiğinde
    permission['role'] = Role.USER;
    onChange(permission);
  };
  return (
    <div className="grid grid-cols-3 gap-x-2">
      <Select
        placeholder="Lütfen rol seçiniz"
        value={permission.role ? permission.role : role}
        onChange={(e: Role) => {
          if (e == Role.ADMIN) {
            selectAdminRole();
          } else {
            onChange({ role: e });
          }
          setMultipleCascaderDisabled(e == Role.ADMIN);
        }}
        options={[
          { value: Role.ADMIN, label: i18n.t('app.role.' + Role.ADMIN) },
          { value: Role.USER, label: i18n.t('app.role.' + Role.USER) },
        ]}
      />
      {permission?.role != Role.ADMIN ? (
        <div className="col-span-2">
          <MultipleCascader
            isShowChild={true}
            items={cascaderItems}
            value={multipleCascaderValue}
            disabled={multipleCascaderDisabled}
            onChange={multipleCascaderChange}
            loading={false}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
