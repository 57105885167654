import {
  ChoiceAnswerResponse,
  ExerciseAnswerRequestModel,
  ExerciseChoicesModel,
  ExerciseConversationModel,
  ExerciseDefinitionContentModel,
  ExerciseDefinitionInteractionModel,
  MediaOrderAnswerResponse,
  OrderAnswerResponse,
} from '@shared/models';
import { DialogMessageBox } from './DialogMessageBox';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { ExerciseDefinitionInteractionType } from '@shared/constants';
import { shuffleToArray } from '@shared/utils';
import { PronunciationVerifier } from '@shared/components/curriculum/PronunciationVerifier';

type ExerciseProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;
  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  onSendDialogResult?: () => void;
  onNextExerciseDialog?: () => void;
  answerResponseModel?: ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse;
};
export const DialogBase = ({
  content,
  interaction,
  isPreview = false,
  isInClass,
  answerRequestModel,
  onChangeAnswerRequestModel,
  onSendDialogResult,
  answerResponseModel,
  onNextExerciseDialog,
}: ExerciseProps) => {
  const [conversation, setConversation] = useState<ExerciseConversationModel>({});
  const [chatIndex, setChatIndex] = useState<number>(0);
  const [isPaused, setIsPaused] = useState(false);
  const [selectedSingleChoices, setSelectedSingleChoices] = useState<{ [index: number]: string }>({});
  const [selectedMultiChoices, setSelectedMultiChoices] = useState<{ [index: number]: { title: string; id: string }[] }>({});
  const [voiceRecognitionResult, setVoiceRecognitionResult] = useState<{ [index: number]: string }>({});

  const [fillInTheBlanksAnswers, setFillInTheBlanksAnswers] = useState<{ [index: number]: string[] }>({});
  const [disabledWords, setDisabledWords] = useState<{ [index: number]: boolean }>({});
  const [shuffledWords, setShuffledWords] = useState<{ [index: number]: string[] }>({});

  const [answerResponseList, setAnswerResponseList] = useState<{ [index: number]: ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse }>({});
  const [showCheckButton, setShowCheckButton] = useState<boolean>(false);

  const [insturctionMap, setInsturctionMap] = useState({
    SINGLE_CHOICE: 'Choose the correct option',
    MULTI_CHOICE: 'Choose the correct options',
    FILL_IN_THE_BLANK: 'Fill in the blank',
    PRONOUNCE: 'Pronounce the word',
  });

  useEffect(() => {
    if (answerResponseModel) {
      setAnswerResponseList((prev) => {
        return { ...prev, [chatIndex - 1]: answerResponseModel };
      });
    }
  }, [answerResponseModel]);

  useEffect(() => {
    if (content.conversation) {
      setConversation(content.conversation);
      let intervalId;
      if (isPreview) {
        setChatIndex(content.conversation.chat?.length! - 1);
      } else {
        intervalId = setInterval(() => {
          if (!isPaused && conversation.chat && chatIndex < conversation.chat.length) {
            if (conversation.chat[chatIndex].interaction) {
              setIsPaused(true); // Interaction'a denk gelirse akış duracak cevaplandırılana kadar
            } else {
              setChatIndex((prev) => prev + 1); // Continue to next chat
            }
          }
        }, 2000);
      }
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [chatIndex, conversation, content?.conversation, isPaused, isPreview]);

  useEffect(() => {
    const interaction = conversation.chat?.[chatIndex]?.interaction;

    // Check if the current interaction is a FILL_IN_THE_BLANKS exercise
    if (interaction && interaction.orderItems) {
      const numBlanks = interaction.orderItems?.length || 0;

      // Initialize selected words if not already initialized
      if (!fillInTheBlanksAnswers[chatIndex]) {
        setFillInTheBlanksAnswers((prev) => ({
          ...prev,
          [chatIndex]: Array(numBlanks).fill(''), // Initialize blanks with empty strings
        }));

        setShuffledWords((prev) => ({
          ...prev,
          [chatIndex]: shuffleToArray(interaction.orderItems!.map((item) => item)), // Shuffle words only once for the current exercise
        }));
      }
    }
  }, [chatIndex, conversation.chat]);

  const onSingleChoice = (choice: ExerciseChoicesModel) => {
    if (onChangeAnswerRequestModel) {
      onChangeAnswerRequestModel({ conversationItemId: conversation?.chat![chatIndex].id, choiceIds: [choice.id!] });
    }
    // Backend isteğinden sonra Dialoğu devam ettir ve sonraki mesajı göster
    setSelectedSingleChoices((prev) => ({
      ...prev,
      [chatIndex]: choice.title!, // Store the choice for this specific index
    }));
    setShowCheckButton(true);
  };

  const handleWordClick = (word, wordIndex, exerciseIndex) => {
    setFillInTheBlanksAnswers((prev) => {
      const updatedAnswers = { ...prev };
      const blanks = updatedAnswers[exerciseIndex] || [];

      // Find the first available blank (empty string) and insert the selected word
      const firstEmptyIndex = blanks.indexOf('');
      if (firstEmptyIndex !== -1) {
        blanks[firstEmptyIndex] = word;
        updatedAnswers[exerciseIndex] = blanks;

        // Disable the clicked word button
        setDisabledWords((prevDisabled) => ({
          ...prevDisabled,
          [wordIndex]: true, // Disable the word that was clicked
        }));
      }

      if (onChangeAnswerRequestModel) {
        onChangeAnswerRequestModel({
          conversationItemId: conversation?.chat![chatIndex].id,
          orderItems: updatedAnswers[exerciseIndex].map((word) => word[0]),
        });
      }

      // Check if all blanks are filled after updating
      const allFilled = blanks.every((w) => w !== '');
      setShowCheckButton(allFilled); // Show "Cevapları Göster" if all blanks are filled

      return updatedAnswers;
    });
  };

  const handleBlankClick = (blankIndex, exerciseIndex) => {
    setFillInTheBlanksAnswers((prev) => {
      const updatedAnswers = { ...prev };
      const blanks = updatedAnswers[exerciseIndex] || [];

      const wordToRemove = blanks[blankIndex];
      if (wordToRemove) {
        // Find the index of the word on the right side and re-enable the button
        const wordIndex = shuffledWords[exerciseIndex].indexOf(wordToRemove);
        if (wordIndex !== -1) {
          setDisabledWords((prevDisabled) => ({
            ...prevDisabled,
            [wordIndex]: false, // Re-enable the word button
          }));
        }

        // Clear the word from the blank
        blanks[blankIndex] = '';
        updatedAnswers[exerciseIndex] = blanks;

        if (onChangeAnswerRequestModel) {
          onChangeAnswerRequestModel({
            conversationItemId: conversation?.chat![chatIndex].id,
            orderItems: updatedAnswers[exerciseIndex].map((word) => word[0]),
          });
        }

        // Hide the "Cevapları Göster" button if any blanks are cleared
        setShowCheckButton(false);
      }

      return updatedAnswers;
    });
  };

  const onCheckAnswer = () => {
    if (onSendDialogResult && !voiceRecognitionResult[chatIndex]) {
      onSendDialogResult();
    }

    setDisabledWords({});
    setIsPaused(false);
    setChatIndex((prev) => prev + 1); // Move to the next step
    setShowCheckButton(false); // Reset the button visibility
  };

  const onMultiChoice = (choice: ExerciseChoicesModel) => {
    setSelectedMultiChoices((prev) => {
      const updatedChoices = { ...prev };
      const currentChoices = updatedChoices[chatIndex] || [];

      // Check if the choice is already selected (by comparing its id or title)
      const isAlreadySelected = currentChoices.some((item) => item.id === choice.id);

      if (isAlreadySelected) {
        // Remove the choice if it's already selected
        updatedChoices[chatIndex] = currentChoices.filter((item) => item.id !== choice.id);
      } else {
        // Add the choice if it's not already selected
        updatedChoices[chatIndex] = [...currentChoices, { title: choice.title!, id: choice.id! }];
      }

      // Show the "Cevapları Göster" button when at least one choice is made
      setShowCheckButton(updatedChoices[chatIndex].length > 0);

      if (onChangeAnswerRequestModel) {
        onChangeAnswerRequestModel({
          conversationItemId: conversation?.chat![chatIndex].id,
          choiceIds: updatedChoices[chatIndex].map((item) => item.id),
        });
      }

      return updatedChoices;
    });
  };

  return (
    <>
      {isInClass ? (
        <div className="flex flex-row h-full w-full bg-white pt-10 pb-10 p-5">
          <div className={'w-3/6 border-dashed border-1 border h-full p-3 '}>
            <DialogMessageBox
              conversation={conversation}
              chatIndex={chatIndex}
              selectedSingleChoices={selectedSingleChoices}
              selectedMultiChoices={selectedMultiChoices}
              voiceRecognitionResult={voiceRecognitionResult}
              fillInTheBlanksAnswers={fillInTheBlanksAnswers}
              answerResponseList={answerResponseList}
              onBlankClick={handleBlankClick}
            />
          </div>
          {conversation.chat && conversation.chat[chatIndex]?.interaction ? (
            <div className="w-3/6 mt-3 text-center flex flex-col justify-center space-y-6 h-full">
              <span style={{ fontWeight: 'bold', color: '#722ED1' }}>
                {conversation.chat[chatIndex]?.interaction?.instruction?.title
                  ? conversation.chat[chatIndex]?.interaction?.instruction?.title
                  : insturctionMap[conversation.chat[chatIndex]?.interaction?.type!]}
              </span>
              <div className="space-x-2 space-y-2 w-full items-center justify-center content-center">
                {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.SINGLE_CHOICE ? (
                  conversation.chat[chatIndex]?.interaction?.choices?.map((c) => {
                    const isSelected = selectedSingleChoices[chatIndex] === c.title;
                    return (
                      <Button key={c.id} type="default" onClick={() => onSingleChoice(c)} className={isSelected ? 'border border-blue-500' : ''}>
                        {c.title}
                      </Button>
                    );
                  })
                ) : (
                  <></>
                )}
                {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.MULTI_CHOICE ? (
                  conversation.chat[chatIndex]?.interaction?.choices?.map((c) => {
                    const isSelected = selectedMultiChoices[chatIndex]?.some((item) => item.title === c.title!);
                    return (
                      <Button key={c.id} type="default" onClick={() => onMultiChoice(c)} className={isSelected ? 'border border-blue-500' : ''}>
                        {c.title}
                      </Button>
                    );
                  })
                ) : (
                  <></>
                )}
                {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS ? (
                  <>
                    {shuffledWords[chatIndex]?.map((word, wordIndex) => (
                      <Button key={wordIndex} type="default" disabled={disabledWords[wordIndex]} onClick={() => handleWordClick(word, wordIndex, chatIndex)}>
                        {word}
                      </Button>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.PRONOUNCE ? (
                  <PronunciationVerifier
                    onVerify={(e) => {
                      setShowCheckButton(true);
                      setVoiceRecognitionResult((prev) => ({
                        ...prev,
                        [chatIndex]: e ? 'TRUE' : 'FALSE',
                      }));
                      setAnswerResponseList((prev) => {
                        return { ...prev, [chatIndex]: { success: e } };
                      });
                    }}
                    texts={conversation.chat[chatIndex]?.interaction?.textSpeech!}
                  />
                ) : (
                  <></>
                )}
              </div>

              {showCheckButton && (
                <div className="flex w-full justify-center">
                  <Button type="primary" onClick={onCheckAnswer}>
                    Sonucu Göster
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {chatIndex === conversation?.chat?.length && (
            <div className="flex flex-col justify-center items-center h-full w-3/6 space-y-6">
              <div className="flex text-center justify-center items-center text-2xl font-bold mb-4">Dialog Egzersizi Tamamlandı</div>

              {Object.values(answerResponseList).every((result) => result.success) ? (
                <div className="flex flex-col justify-center items-center bg-light-success p-4 rounded-lg shadow-lg mx-8">
                  <span className="text-center text-xl text-success font-semibold">Tebrikler tüm dialogları doğru cevaplandırdınız!</span>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center bg-light-error p-4 rounded-lg shadow-lg">
                  <span className="text-center text-2xl font-bold text-error">
                    {Object.values(answerResponseList).filter((result) => result.success).length}
                    <span className="text-2xl text-error">/</span>
                    {Object.keys(answerResponseList).length}
                  </span>
                  <span className="text-xl text-black mt-2">diyaloğu bildiniz</span>
                </div>
              )}
            </div>
          )}
        </div>
      ) : isPreview ? (
        <div className="flex flex-col h-full w-full bg-white pt-10 pb-10 p-5">

          <div className={conversation.chat && conversation.chat[chatIndex]?.interaction ? 'h-4/6 w-full' : 'w-full h-full'}>
            <DialogMessageBox conversation={conversation} />
          </div>
          {conversation.chat && conversation.chat.length > 0 && conversation.chat[chatIndex]?.interaction ? (
            <div className="h-2/6 mt-3 text-center w-full space-y-6">
              <span style={{ fontWeight: 'bold', color: '#722ED1' }}>
                {conversation.chat[chatIndex]?.interaction?.instruction?.title
                  ? conversation.chat[chatIndex]?.interaction?.instruction?.title
                  : insturctionMap[conversation.chat[chatIndex]?.interaction?.type!]}
              </span>
              <div className="space-x-2 space-y-2 w-full items-center justify-center content-center">
                {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.SINGLE_CHOICE ? (
                  conversation.chat[chatIndex]?.interaction?.choices?.map((c) => (
                    <Button key={c.id} type="default">
                      {c.title}
                    </Button>
                  ))
                ) : (
                  <></>
                )}
                {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.MULTI_CHOICE ? (
                  conversation.chat[chatIndex]?.interaction?.choices?.map((c) => (
                    <Button key={c.id} type="default">
                      {c.title}
                    </Button>
                  ))
                ) : (
                  <></>
                )}
                {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS ? (
                  shuffleToArray(conversation.chat[chatIndex].interaction?.orderItems!).map((c) => <Button type="default">{c}</Button>)
                ) : (
                  <></>
                )}
                {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.PRONOUNCE ? (
                  <PronunciationVerifier onVerify={(e) => { }} texts={conversation.chat[chatIndex]?.interaction?.textSpeech!} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="flex flex-col h-full w-full bg-white p-3">
          <div className="w-full border-dashed  border h-1/2 p-3 ">
            <DialogMessageBox
              conversation={conversation}
              chatIndex={chatIndex}
              selectedSingleChoices={selectedSingleChoices}
              selectedMultiChoices={selectedMultiChoices}
              voiceRecognitionResult={voiceRecognitionResult}
              fillInTheBlanksAnswers={fillInTheBlanksAnswers}
              answerResponseList={answerResponseList}
              onBlankClick={handleBlankClick}
            />
          </div>
          {conversation.chat && conversation.chat[chatIndex]?.interaction ? (
            <div className='h-1/2 flex flex-col   bg-white'>
              <div className="w-full mt-3 text-center flex flex-col justify-center  h-full ">
                <span style={{ fontWeight: 'bold', color: '#722ED1' }}>
                  {conversation.chat[chatIndex]?.interaction?.instruction?.title
                    ? conversation.chat[chatIndex]?.interaction?.instruction?.title
                    : insturctionMap[conversation.chat[chatIndex]?.interaction?.type!]}
                </span>
                <div className="space-x-2 space-y-2 h-full w-full items-center justify-center content-center">
                  {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.SINGLE_CHOICE ? (
                    conversation.chat[chatIndex]?.interaction?.choices?.map((c) => {
                      const isSelected = selectedSingleChoices[chatIndex] === c.title;
                      return (
                        <Button key={c.id} type="default" onClick={() => onSingleChoice(c)} className={isSelected ? 'border border-blue-500' : ''}>
                          {c.title}
                        </Button>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.MULTI_CHOICE ? (
                    conversation.chat[chatIndex]?.interaction?.choices?.map((c) => {
                      const isSelected = selectedMultiChoices[chatIndex]?.some((item) => item.title === c.title!);
                      return (
                        <Button key={c.id} type="default" onClick={() => onMultiChoice(c)} className={isSelected ? 'border border-blue-500' : ''}>
                          {c.title}
                        </Button>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS ? (
                    <>
                      {shuffledWords[chatIndex]?.map((word, wordIndex) => (
                        <Button key={wordIndex} type="default" disabled={disabledWords[wordIndex]} onClick={() => handleWordClick(word, wordIndex, chatIndex)}>
                          {word}
                        </Button>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                  {conversation.chat[chatIndex]?.interaction?.type == ExerciseDefinitionInteractionType.PRONOUNCE ? (
                    <PronunciationVerifier
                      onVerify={(e) => {
                        setShowCheckButton(true);
                        setVoiceRecognitionResult((prev) => ({
                          ...prev,
                          [chatIndex]: e ? 'TRUE' : 'FALSE',
                        }));
                        setAnswerResponseList((prev) => {
                          return { ...prev, [chatIndex]: { success: e } };
                        });
                      }}
                      texts={conversation.chat[chatIndex]?.interaction?.textSpeech!}
                    />
                  ) : (
                    <></>
                  )}
                </div>


              </div>
              {showCheckButton && (
                <Button
                  block
                  onClick={onCheckAnswer}
                  className="bg-[#D3ADF7] p-0 h-auto text-lg text-custom-purple w-full hover:!bg-purple-400 hover:!border-purple-500 hover:!text-white !transition-colors"
                >
                  Sonucu Göster
                </Button>
              )}
            </div>
          ) : (
            <></>
          )}

          {chatIndex === conversation?.chat?.length && (
            <div className="flex flex-col  items-center h-full w-full space-y-4 mt-3 justify-between">
              {Object.values(answerResponseList).every((result) => result.success) ? (
                <div className="flex flex-col justify-center items-center bg-light-success p-4 rounded-lg shadow-lg ">
                  <span className="text-center text-xl text-success font-semibold">Tebrikler tüm dialogları doğru cevaplandırdınız!</span>
                </div>
              ) : (
                <div className="flex flex-row justify-center items-center bg-light-error p-4 rounded-lg shadow-lg">
                  <span className=" text-xl font-bold text-error">
                    {Object.values(answerResponseList).filter((result) => result.success).length}
                    <span className="text-xl text-error ">/</span>
                    {Object.keys(answerResponseList).length}
                    <span className="ml-2">Doğru Cevaplandı!</span>
                  </span>
                </div>
              )}

              <Button
                onClick={() => onNextExerciseDialog!()}
                block
                className="bg-[#D3ADF7]  h-auto text-lg text-custom-purple w-full hover:!bg-purple-400 hover:!border-purple-500 hover:!text-white !transition-colors"
              >
                Devam Et
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
