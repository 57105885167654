import Layout, { Content } from 'antd/es/layout/layout';
import { useEffect, useMemo } from 'react';
import { RouteBreadcrumb,HeaderComponent, AppNavigationView } from '@shared/components';
import { Route, Routes } from 'react-router-dom';
import { useUserContext } from '@shared/contexts';
import { menuGroups, pageToMenuItem } from '@shared/utils';
import { LayoutEnums, Role } from '@shared/constants';
import { HeaderDropDown } from '../header/HeaderDropDown';

const bc = new BroadcastChannel('auth.channel.joedtech');
export const PlatformWelcomeLayout = ({ pages }) => {
  const { user } = useUserContext();

  useEffect(() => {
    bc.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        window.location.reload();
      }
    };
  }, []);

  const menuItems = pages.map(pageToMenuItem);
  const { userMenuItems, menu } = useMemo(() => {
    if (user?.jwt) {
      const userMenuItems = menuItems.filter((item) => item.roles.includes(Role.ADMIN) && item.layouts.includes(LayoutEnums.WELCOME_LAYOUT));

      let menu = menuGroups.map((group) => ({
        ...group,
        label: typeof group.title === 'string' ? <span>{group.title}</span> : group.title,
        children: userMenuItems.filter((item) => item.group === group.key),
      })) as any[];

      menu = menu.filter((item) => item.children.length > 0);
      menu = [...userMenuItems.filter((item) => item.group === 'flat'), ...menu];
      return { userMenuItems, menu };
    }
    return { userMenuItems: [], menu: [] };
  }, [menuItems, user]);

  return (
    <Layout style={{ height: '100svh' }}>
      <HeaderComponent
          logoSrc={require('../../../../../statics/starlogo.png')}
          appNavigationView={<AppNavigationView />}
          appNavigationViewBackgroundColor={'FFE4DE'}
          headerDropDown={<HeaderDropDown settingUrl={`/${LayoutEnums.TEACHER}/setting`} bgColor="#FFFFFF" bgOpacity="70" />}
        />
      <Layout>
        <RouteBreadcrumb items={userMenuItems} />
        <Content style={{ margin: '14px 18px 18px', overflow: 'auto', backgroundColor: '#F5F5F5' }}>
          <Routes>
            {pages.map(([key, Comp]) => (
              <Route
                key={key}
                path={Comp.path}
                element={<Comp />}
                handle={(a, b, c) => {
                }}
              />
            ))}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};