{
  "app": {
    "hello": "merhaba",
    "monday": "Pt",
    "tuesday": "S",
    "wednesday": "Ç",
    "thursday": "Pş",
    "friday": "C",
    "saturday": "Ct",
    "sunday": "Pz",
    "morning": "S",
    "afternoon": "Ö",
    "night": "A",
    "midWeek": "Hİ",
    "weekend": "HS",
    "noteType": {
      "COMPLAINT": "Şikayet",
      "GENERAL": "Genel",
      "DEMAND": "İstek",
      "TECHNICAL_FAILURE": "Teknik Arıza"
    },
    "branchType": {
      "BRANCH": "Şube",
      "FRANCHISE": "Bayilik"
    },
    "enrollmentStatus": {
      "INFO": "Bilgi",
      "READY_FOR_PLANNING": "Planlamaya hazır",
      "STARTED": "Başladı",
      "DONE": "Bitti",
      "CANCELED": "İptal edildi"
    },
    "enrollmentType": {
      "INDIVIDUAL": "Bireysel",
      "GROUP": "Grup",
      "ENTERPRISE": "Kurumsal"
    },
    "subPermissions": {
      "teacher": "Öğretmen",
      "observer": "Gözetmen",
      "student": "Öğrenci"
    },
    "classStatus": {
      "NOT_STARTED": "Başlamadı",
      "STARTED": "Başladı",
      "COMPLETED": "Tamamlandı",
      "CANCELED": "İptal edildi"
    },
    "preplacementStatus": {
      "SUCCESS": "Başarılı",
      "FAILED": "Başarısız",
      "CONDITIONAL": "Şartlı"
    },
    "classHomeworkStatus": {
      "ACTIVE": "Aktif",
      "WAITING": "Planlanmış",
      "EXPIRED": "Tamamlandı"
    },
    "demandStatus": {
      "ACTION_REQUIRED": "Aksiyon Bekleniyor",
      "DELAYED_ACTION_REQUIRED": "İleri Tarihte Aksiyon Bekleniyor",
      "PLANNING": "Planlamada",
      "ACTIVE": "Aktif",
      "SUCCESS": "Başarılı",
      "CONDITIONAL": "Şartlı",
      "FROZEN": "Donduruldu",
      "FAILED": "Başarısız",
      "CANCELED": "İptal edildi"
    },
    "planningMeta": {
      "LEVEL_NOT_FIT": "Kur Seviyesi Uyuşmuyor",
      "CALENDAR_NOT_FIT": "Takvim Uyuşmuyor",
      "DATE_RANGE_NOT_FIT": "Tarih Aralığı Uyuşmuyor",
      "MAX_QUOTA_EXCEDED": "Sınıf Kapasitesi Aşıldı",
      "OPTIMAL_QUOTA_EXCEDED": "Önerilen Sınıf Kapasitesi Aşıldı"
    },
    "gender": {
      "MALE": "Erkek",
      "FEMALE": "Kadın"
    },
    "role": {
      "ADMIN": "Yönetici",
      "USER": "Kullanıcı"
    }
  },
  "error": {
    "payment.required": "Yetersiz Kur Kredi Bakiyesi. Lütfen Kredi Talebi Oluşturun",
    "forbidden": "Bu işlem için yetkiniz yok",
    "unauthorized": "Kullanıcı adı veya parolanız hatalı",
    "not.found": "Erişmek istediğiniz varlık bulunamadı",
    "bad.request": "Geçersiz istek",
    "internal.server.error": "Beklenmeyen bir hata meydana geldi",
    "conflict": "Aynı özelliklere varlık zaten bulunmaktadır",
    "argument.out.of.range": "Geçersiz parametre aralığı",
    "argument.invalid": "Geçersiz parametre",
    "argument.not.provided": "Parametre sağlanmadı",
    "entity.is.using.by.another.entity": "Bu varlık başka bir varlık tarafından kullanılıyor",
    "account.is.not.active": "Hesap aktif değil",
    "account.not.found": "Hesap bulunamadı",
    "account.activation.token.expired": "Hesap aktivasyon kodunun süresi dolmuş",
    "account.activation.token.required": "Hesap aktivasyon kodu gereklidir",
    "account.reset.password.token.required": "Parola sıfırlama kodu gereklidir",
    "account.reset.password.token.expired": "Parola sıfırlama kodunun süresi dolmuş",
    "users.cant.delete.themselves": "Kullanıcı kendi hesabını silemez",
    "users.cant.update.permissions.themselves": "Kullanıcı kendi izinlerini güncelleyemez",
    "role.not.found": "Rol bulunamadı",
    "passwords.dont.match": "Parolaler eşleşmiyor",
    "locale.not.found": "Dil ayarı bulunamadı",
    "product.not.found": "Ürün bulunamadı",
    "unit.not.found": "Birim bulunamadı",
    "unit.name.not.found": "Birim adı bulunamadı",
    "unit.cant.update": "Birim güncellenemiyor",
    "topic.not.found": "Konu bulunamadı",
    "topic.name.not.found": "Konu adı bulunamadı",
    "topic.type.not.found": "Konu türü bulunamadı",
    "page.not.found": "Sayfa bulunamadı",
    "page.content.cant.be.empty": "Sayfa içeriği boş olamaz",
    "exercise.not.found": "Alıştırma bulunamadı",
    "exercise.content.not.found": "Alıştırma içeriği bulunamadı",
    "exercise.group.not.found": "Alıştırma grubu bulunamadı",
    "exercise.group.name.not.found": "Alıştırma grubu adı bulunamadı",
    "invalid.exercise.type": "Geçersiz alıştırma türü",
    "calendar.entry.not.found": "Takvim girdisi bulunamadı",
    "calendar.entry.name.not.found": "Takvim girdisi adı bulunamadı",
    "calendar.entry.date.not.found": "Takvim girdisi tarihi bulunamadı",
    "calendar.entry.time.range.not.found": "Takvim girdisi zaman aralığı bulunamadı",
    "education.level.not.found": "Eğitim seviyesi bulunamadı",
    "education.theme.not.found": "Eğitim teması bulunamadı",
    "education.type.not.found": "Eğitim türü bulunamadı",
    "education.field.not.found": "Eğitim alanı bulunamadı",
    "content.version.not.found": "İçerik sürümü bulunamadı",
    "content.version.cant.update": "İçerik sürümü güncellenemiyor",
    "content.version.cant.delete": "İçerik sürümü silinemiyor",
    "content.version.cant.archive": "İçerik sürümü arşivlenemiyor",
    "content.version.should.be.reviewed.before.publish": "İçerik sürümü yayınlanmadan önce incelenmelidir",
    "content.version.should.be.published.before.set.as.default": "İçerik sürümü varsayılan olarak ayarlanmadan önce yayınlanmalıdır",
    "content.version.update.package.not.found": "İçerik sürümü güncelleme paketi bulunamadı",
    "content.version.name.not.found": "İçerik sürümü adı bulunamadı",
    "country.code.not.found": "Ülke kodu bulunamadı",
    "country.name.not.found": "Ülke adı bulunamadı",
    "city.code.not.found": "Şehir kodu bulunamadı",
    "city.name.not.found": "Şehir adı bulunamadı",
    "address.not.found": "Adres bulunamadı",
    "postal.code.not.found": "Posta kodu bulunamadı",
    "phone.number.not.found": "Telefon numarası bulunamadı",
    "begin.time.not.found": "Başlangıç zamanı bulunamadı",
    "end.time.not.found": "Bitiş zamanı bulunamadı",
    "end.time.should.be.after.begin.time": "Bitiş zamanı, başlangıç zamanından sonra olmalıdır",
    "hour.is.out.of.range": "Saat aralığı dışındadır",
    "minute.is.out.of.range": "Dakika aralığı dışındadır",
    "start.date.not.found": "Başlangıç tarihi bulunamadı",
    "start.date.and.end.date.not.found": "Başlangıç ve bitiş tarihi bulunamadı",
    "end.date.not.found": "Bitiş tarihi bulunamadı",
    "start.date.should.be.before.end.date": "Başlangıç tarihi, bitiş tarihinden önce olmalıdır",
    "owner.not.found": "Sahip bulunamadı",
    "email.is.already.readed": "E-posta zaten okundu",
    "email.receiver.not.found": "E-posta alıcısı bulunamadı",
    "email.not.found": "E-posta bulunamadı",
    "invalid.teacher.score": "Geçersiz öğretmen puanı",
    "invalid.exam.score": "Geçersiz sınav puanı",
    "invalid.homework.score": "Geçersiz ödev puanı",
    "invalid.attendance.score": "Geçersiz katılım puanı",
    "invalid.attendance.score.threshold": "Geçersiz katılım puanı eşiği",
    "invalid.fail.score.threshold": "Geçersiz başarısızlık puanı eşiği",
    "invalid.success.score.threshold": "Geçersiz başarı puanı eşiği",
    "education.level.success.requirement.not.found": "Eğitim seviyesi başarı gereksinimi bulunamadı",
    "brand.product.already.exists": "Marka ürünü zaten mevcut",
    "brand.not.found": "Marka bulunamadı",
    "brand.user.not.found": "Marka kullanıcısı bulunamadı",
    "brand.name.not.found": "Marka adı bulunamadı",
    "brand.content.version.update.package.already.applied": "Marka içerik sürümü güncelleme paketi zaten uygulanmış",
    "brand.content.version.update.package.already.applying": "Marka içerik sürümü güncelleme paketi zaten uygulanıyor",
    "branch.not.found": "Şube bulunamadı",
    "branch.user.not.found": "Şube kullanıcısı bulunamadı",
    "branch.cant.change": "Şube değiştirilemiyor",
    "branch.name.not.found": "Şube adı bulunamadı",
    "enrollment.not.found": "Kayıt bulunamadı",
    "enrollment.type.not.found": "Kayıt türü bulunamadı",
    "enrollment.type.cant.change": "Kayıt türü değiştirilemiyor",
    "enrollment.cant.update": "Kayıt güncellenemiyor",
    "enrollment.cant.accept.new.student": "Bu kayda yeni öğrenci ekleyemezsiniz",
    "enrollment.cant.accept.new.observer": "Bu kayda yeni gözetmen ekleyemezsiniz",
    "enrollment.basic.info.not.found": "Kayıt temel bilgisi bulunamadı",
    "enrollment.status.cant.change": "Kayıt durumu değiştirilemiyor",
    "observer.not.found": "Gözetmen bulunamadı",
    "preplacement.already.exists": "Kayıt zaten mevcut",
    "class.planning.not.found": "Ders planlaması bulunamadı",
    "class.planning.status.cant.change": "Ders planlama durumu değiştirilemiyor",
    "seance.not.found": "Seans bulunamadı",
    "seance.name.not.found": "Seans adı bulunamadı",
    "seance.item.not.found": "Seans öğesi bulunamadı",
    "seance.item.duration.should.greater.than.zero": "Seans öğesi süresi sıfırdan büyük olmalıdır",
    "seance.item.time.range.should.be.increasing.order": "Seans öğesi zaman aralığı artan sırada olmalıdır",
    "at.least.one.student.required": "En az bir öğrenci gereklidir",
    "preplacement.not.found": "Kayıt bulunamadı",
    "class.room.not.found": "Sınıf odası bulunamadı",
    "class.room.name.not.found": "Sınıf odası adı bulunamadı",
    "class.room.quota.not.found": "Sınıf odası kotası bulunamadı",
    "class.room.max.quota.exceeded": "Derslik maksimum kota aşıldı",
    "class.room.optimal.quota.exceeded": "Derslik optimal kota aşıldı",
    "class.room.quota.cant.be.higher.than.max.quota": "Derslik kotası maksimum kotadan yüksek olamaz",
    "class.room.calendar.not.fit": "Derslik takvimi uymuyor",
    "teacher.level.not.fit": "Öğretmen seviyesi uygun değil",
    "demand.status.cant.change": "Talep durumu değiştirilemez",
    "demand.basic.info.not.found": "Talep temel bilgileri bulunamadı",
    "demand.level.not.fit": "Talep seviyesi uygun değil",
    "demand.not.found": "Talep bulunamadı",
    "demand.name.not.found": "Talep adı bulunamadı",
    "demand.calendar.not.found": "Talep takvimi bulunamadı",
    "demand.calendar.not.fit": "Talep takvimi uymuyor",
    "demand.is.already.completed": "Talep zaten tamamlandı",
    "demand.is.already.active": "Talep zaten aktif",
    "demand.is.canceled": "Talep iptal edildi",
    "demand.level.doesnt.belong.to.branch": "Talep seviyesi şubeye ait değil",
    "demand.is.not.ready.for.planning": "Talep planlama için hazır değil",
    "demand.level.not.found": "Talep seviyesi bulunamadı",
    "teacher.not.found": "Öğretmen bulunamadı",
    "teacher.availability.levels.not.found": "Öğretmen uygunluk seviyeleri bulunamadı",
    "teacher.availability.calendar.not.found": "Öğretmen uygunluk takvimi bulunamadı",
    "teacher.availability.calendar.not.fit": "Öğretmen uygunluk takvimi uymuyor",
    "invalid.day.of.week": "Geçersiz gün",
    "day.of.weeks.required": "Haftanın günleri gerekli",
    "class.not.found": "Sınıf bulunamadı",
    "class.is.not.active": "Ders aktif değil",
    "class.name.not.provided": "Ders adı sağlanmadı",
    "class.seance.items.overlaping": "Ders seansı öğeleri çakışıyor",
    "class.already.started": "Ders zaten başladı",
    "class.can.not.be.started": "Ders başlatılamaz",
    "class.already.completed": "Ders zaten tamamlandı",
    "class.should.be.started.before.completing": "Zaten başlatılmamış bir sınıfı sonlandırmak istediniz.",
    "class.students.should.be.evaluated.before.completing": "Sınıf sonlandırılmadan önce tüm öğrenciler değerlendirilmelidir",
    "class.students.already.evaluated": "Öğrenciler zaten değerlendirildi",
    "teacher.student.evaluation.items.not.found": "Öğretmen-öğrenci değerlendirme öğeleri bulunamadı",
    "teacher.student.evaluation.not.found": "Öğretmen-öğrenci değerlendirmesi bulunamadı",
    "teacher.student.evaluation.invalid.score": "Öğretmen-öğrenci değerlendirmesi geçersiz puan",
    "teacher.student.evaluation.item.not.found": "Öğretmen-öğrenci değerlendirme öğesi bulunamadı",
    "student.already.exists": "Öğrenci zaten mevcut",
    "student.not.found": "Öğrenci bulunamadı",
    "class.calendar.entry.not.found": "Ders takvimi bulunamadı",
    "class.calendar.entry.name.is.required": "Ders takvimi adı zorunludur",
    "class.calendar.entry.already.started": "Ders takvimi zaten başladı durumunda",
    "class.calendar.entry.already.postponed": "Ders takvimi zaten ertelendi durumunda",
    "in.class.is.not.available.now": "Ders şu anda mevcut değil",
    "class.activity.not.found": "Ders etkinliği bulunamadı",
    "class.activity.already.completed": "Ders etkinliği zaten tamamlandı",
    "attendance.not.found": "Devam durumu bulunamadı",
    "make.attendance.first": "Önce devam durumu yapın",
    "not.completed.class.activity.exist": "Tamamlanmamış ders etkinliği mevcut",
    "student.not.active": "Öğrenci aktif değil",
    "demand.is.frozen.before": "Bu kayıt daha önce dondurulduğu için tekrar dondurma işlemi yapamazmısınız.",
    "enrollment.cant.delete": "Yalnızca bilgi alma aşamasında olan kayıtlar silinebilir"
  },
  "notification": {
    "you.are.brand.user": "Artık marka kullanıcısınız",
    "you.are.brand.user.description": "{{brand}}'de artık marka kullanıcısısınız.",
    "you.are.not.brand.user": "Artık marka kullanıcısı değilsiniz",
    "you.are.not.brand.user.description": "{{brand}}'de artık marka kullanıcısı değilsiniz",
    "you.are.teacher.now": "Öğretmen olarak tanımlandınız",
    "you.are.teacher.now.description": "{{branch}} şubesinde öğretmen olarak tanımlandınız.",
    "you.are.not.teacher.anymore": "Artık öğretmen değilsiniz",
    "you.are.not.teacher.anymore.description": "{{branch}}'de artık öğretmen değilsiniz",
    "you.are.branch.user": "Artık şube kullanıcısınız",
    "you.are.branch.user.description": "{{branch}} Şubesinde artık şube kullanıcısısınız: ({{role}})",
    "branch.user.created": "Şubenizde yeni bir kullanıcı oluşturuldu",
    "branch.user.created.description": "{{branch}} Şubesinde yeni bir kullanıcı oluşturuldu: {{account}} ({{role}})",
    "brand.user.created": "Markanızda yeni bir kullanıcı oluşturuldu",
    "brand.user.created.description": "{{brand}} Markanızda yeni bir kullanıcı oluşturuldu: {{account}} ({{role}})",
    "brand.user.deleted": "Markanızdan bir kullanıcı silindi",
    "brand.user.deleted.description": "{{brand}} Markanızdan bir kullanıcı silindi: {{account}} ({{role}})",
    "you.are.not.brand.user.anymore.description": "{{brandName}} markasında artık marka kullanıcısı değilsiniz",
    "you.are.not.branch.user": "Artık şube kullanıcısı değilsiniz",
    "you.are.not.branch.user.anymore.description": "{{branchName}} şubesinde artık marka kullanıcısı değilsiniz",
    "course.repetation.registered": "Kur tekrarı işleminiz başarıyla gerçekleştirildi",
    "course.repetation.registered.description": "Tekrar alacağınız kur '{{level}}'",
    "there.is.new.system.content.version": "Markanız için sisteme yeni bir müfredat tanımlandı.",
    "there.is.new.system.content.version.description": "{{brand}} Markanız için yeni bir müfredat bulunuyor: Müfredat İsim: {{contentName}}, Müfredat Versiyon: {{systemVersion}}, Müfredat Açıklama: {{contentDescription}} Kur: ({{level}})"
  },
  "firstPage": {
    "1": "Akıllı Eğitim Çözümlerinde Tek Platform",
    "2": "JOEDTECH ile tanışın",
    "3": "JoedTech eğitim sektöründe kullanılan farklı yazılımları tek bir platformda birleştirerek dil enstitüleri ve eğitim kurumları için entegre bir çözüm sunar.",
    "4": "JoedTech'e giriş yap",
    "5": "Bizimle iletişime geçin",
    "6": "Yapay zeka, otomasyon ve büyük veri analitiği gibi ileri teknolojileri kullanarak, operasyonel verimliliği artırırken, öğrenci deneyimini iyileştiriyor ve sürdürülebilir bir eğitim ekosistemi sağlıyor. Tüm şubeler ve departmanlar arasında kesintisiz etkileşim sağlayan bu platform, eğitimde dijital dönüşümü desteklemek için tasarlandı.",
    "7": "Müşteri Yönetimi",
    "8": "Öğrenci ve veli ilişkilerini yapay zeka destekli CRM sistemiyle optimize ederek, müşteri yönetimini daha verimli ve kişiselleştirilmiş bir hale getirir ve satış süreçlerini kolaylıkla yönetmenizi sağlar.",
    "9": "Özgün Eğitim Sistemi",
    "10": "Kurumlara güncellenebilir müfredat ve zengin egzersiz seçenekleri sunarak, öğrenci merkezli ve yapay zeka destekli kişiselleştirilmiş eğitim çözümleri ile yenilikçi bir eğitim deneyimi sağlar.",
    "11": "Marka ve Şube Yönetimi",
    "12": " Marka ve şubelerinizi tanımlayıp, organizasyon hiyerarşinizi oluşturun. Sınıflarınızı akıllı planlama aracıyla oluşturun. Öğrencilerinize özgün ve dinamik eğitim içerikleri sağlayın.",
    "13": "Yeni Nesil Eğitim Sistemi",
    "14": "JoedTech, öğrencilerinize size özel içeriklerle kurumlarınızda eğitim vermenizi sağlarken öğrencilerinizi ders öncesi egzersizleriyle derse hazırlar ve ders sonrası egzersizleriyle de öğrencilerin derste öğrendiklerini pekiştirmesine yardımcı olur.",
    "15": "İnteraktif Egzersizler",
    "16": "Birbirinden farklı egzersiz tipleriyle öğrencilerinizin öğrenme sürecini daha verimli hale getirir. Yapay zeka destekli doğrulama servisleriyle etkileşimli bir öğrenme deneyimi sağlar.",
    "17": "Eğlenceli ve Etkili Dil Öğrenme Deneyimi",
    "18": "Her seviyeye uygun egzersizler, oyunlaştırılmış görevler ve anlık geri bildirimlerle öğrencilerinize hem hızlı hem de kalıcı bir deneyim sunar.",
    "19": "Çoklu Platform Desteği",
    "20": "Kişisel bilgisayarlarınız, tablet veya mobil cihazlarınızla uyumlu bir şekilde çalışır.",
    "21": "Destek ve talepleriniz için",
    "22": "bize ulaşın",
    "23": "Ad - Soyad",
    "24": "E-posta",
    "25": "Mesaj",
    "26": "Gönder",
    "27": "Eğitim kurumunuzu yönetmeye, hemen şimdi başlayın!"
  }
}
