import { ExerciseAnswerRequestModel, ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel, OrderAnswerResponse } from '@shared/models';
import { shuffleToArray, uuid } from '@shared/utils';
import { Button } from 'antd';
import { useEffect, useState } from 'react';

type ExerciseProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;
  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  answerResponseModel?: OrderAnswerResponse;
};
export const MatchBase = ({ content, interaction, isPreview = false, isInClass, answerRequestModel, onChangeAnswerRequestModel, answerResponseModel }: ExerciseProps) => {
  const [leftSlots, setLeftSlots] = useState<string[]>([]);
  const [rightWords, setRightWords] = useState<Partial<{ word: string; disabled: boolean }>[]>([]);

  useEffect(() => {
    if (interaction.orderItems) {
      setLeftSlots(interaction.orderItems?.map(() => '?') || []);
      setRightWords(shuffleToArray(interaction.orderItems!).map((o) => ({ word: o[1], disabled: false })));
    }
  }, [interaction, interaction.orderItems]);

  const handleSelectWord = (index: number, word: string) => {
    // Find the first available slot with '?'
    const nextAvailableSlot = leftSlots.findIndex((slot) => slot === '?');
    if (nextAvailableSlot !== -1) {
      // Update leftSlots with the selected word
      const updatedLeftSlots = [...leftSlots];
      updatedLeftSlots[nextAvailableSlot] = word;
      setLeftSlots(updatedLeftSlots);

      // Disable the selected word in the rightWords
      const updatedRightWords = [...rightWords];
      updatedRightWords[index].disabled = true;
      setRightWords(updatedRightWords);

      if (onChangeAnswerRequestModel) {
        onChangeAnswerRequestModel({
          ...(answerRequestModel ? answerRequestModel : { orderItems: [] }),
          orderItems: updatedLeftSlots,
        });
      }
    }
  };

  const handleRemoveWord = (index: number) => {
    const wordToRemove = leftSlots[index];
    if (wordToRemove !== '?') {
      // Update leftSlots to restore '?' placeholder
      const updatedLeftSlots = [...leftSlots];
      updatedLeftSlots[index] = '?';
      setLeftSlots(updatedLeftSlots);

      // Enable the corresponding word in the rightWords
      const updatedRightWords = rightWords.map((rw) => (rw.word === wordToRemove ? { ...rw, disabled: false } : rw));
      setRightWords(updatedRightWords);

      if (onChangeAnswerRequestModel) {
        onChangeAnswerRequestModel({
          ...(answerRequestModel ? answerRequestModel : { orderItems: [] }),
          orderItems: updatedLeftSlots,
        });
      }
    }
  };

  return (
    <>
      {isInClass ? (
        <div className="flex flex-row items-center h-full w-full bg-white pt-10 pb-10">
          <div className="m-2 h-4/6 w-3/6 pl-2 pr-2 space-y-2">
            {leftSlots.map((slot, i) => (
              <div key={uuid() + i} className="grid grid-cols-2 gap-2 ">
                <div className="text-center content-center border border-dashed border-mid-gray rounded min-h-10">{interaction.orderItems?.[i][0]}</div>
                <Button
                  className={`h-auto justify-center items-center flex flex-wrap whitespace-normal text-center  border border-dashed  rounded w-full ${
                    answerResponseModel && answerResponseModel.corrections![i].isCorrect ? '!border-2 !border-solid !border-[#70C040]' : ''
                  }`}
                  danger={answerResponseModel && !answerResponseModel.corrections![i].isCorrect}
                  onClick={() => handleRemoveWord(i)}
                >
                  {slot}
                </Button>
              </div>
            ))}
          </div>
          {interaction.orderItems && (
            <div className="h-full w-3/6 mt-3 flex flex-wrap justify-around items-center space-y-6 space-x-1">
              <span className="text-custom-purple text-xl font-bold">{interaction.instruction?.title ?? 'Match'} </span>
              <div className="grid grid-cols-2 gap-2 items-center justify-center content-center h-full w-full ">
                {rightWords.map((o, i) => (
                  <Button
                    key={i}
                    type="default"
                    disabled={o.disabled}
                    className="white-space-normal h-auto items-center justify-center flex flex-wrap "
                    onClick={() => handleSelectWord(i, o.word!)}
                  >
                    <span className="text-wrap">{o.word}</span>
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : isPreview ? (
        <div className="flex flex-col items-center h-full bg-white pt-10 pb-10">
          <div className="m-2 max-h-4/6 w-full pl-2 pr-2 space-y-2">
            {interaction.orderItems?.map((o, i) => (
              <div key={uuid() + i} className="grid grid-cols-2 gap-2 ">
                <div className="text-center content-center border border-dashed border-mid-gray rounded min-h-10">{o[0]}</div>
                <div className="text-center content-center border border-dashed border-mid-gray rounded w-full">{isPreview ? '' : o[1]}</div>
              </div>
            ))}
          </div>
          {interaction.orderItems ? (
            <div className="h-2/6 mt-3 text-center space-y-6 space-x-1">
              <span className="text-custom-purple text-xl font-bold">{interaction.instruction?.title ?? 'Match'} </span>
              <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                {shuffleToArray(interaction.orderItems!).map((o, i) => (
                  <Button key={i} type="default" className="whitespace-normal h-auto">
                    {o[1]}
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center h-full bg-white py-3 px-5 relative">
          <div className="m-2 lg:h-4/6 md:h-3/6 w-full pl-2 pr-2 space-y-2">
            {leftSlots.map((slot, i) => (
              <div key={uuid() + i} className="grid grid-cols-2 gap-2 ">
                <div className="text-center content-center border border-dashed border-mid-gray rounded min-h-10">{interaction.orderItems?.[i][0]}</div>
                <Button
                  className={`h-auto justify-center items-center flex flex-wrap whitespace-normal text-center  border border-dashed  rounded w-full ${
                    answerResponseModel && answerResponseModel.corrections![i].isCorrect ? '!border-2 !border-solid !border-[#70C040]' : ''
                  }`}
                  danger={answerResponseModel && !answerResponseModel.corrections![i].isCorrect}
                  onClick={() => handleRemoveWord(i)}
                >
                  <span className="text-wrap">{slot} </span>
                </Button>
              </div>
            ))}
          </div>
          {interaction.orderItems ? (
            <div className="lg:h-2/6 md:h-3/6 mt-3 text-center space-y-2 space-x-1">
              <span className="text-custom-purple text-xl font-bold">{interaction.instruction?.title ?? 'Match'} </span>
              <div className="space-x-2 w-fit items-center text-center justify-center content-center flex flex-wrap">
                {rightWords.map((o, i) => (
                  <Button key={i} type="default" disabled={o.disabled} className="whitespace-normal h-auto " onClick={() => handleSelectWord(i, o.word!)}>
                    <span className="text-wrap">{o.word}</span>
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
