import { MediaModel } from "@shared/models"
import { Image } from "antd";

type ImageViewProps = {
    image:MediaModel,
    isPreview?:boolean,
    height?:string,
    width?:string
    className?:string

}
export const ImageView = ({image,isPreview=false,height="100%",width="auto", className}:ImageViewProps)=>{
    return (
        <Image height={height} width={width} className={className}  style={{display:'block'}} src={image.resourceUrl ? image.resourceUrl : "https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg"} preview={isPreview} />
    )
}