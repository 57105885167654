import { DeleteTwoTone } from '@ant-design/icons';
import { useUserNotificationContext } from '@shared/contexts';
import { Badge, List, Pagination, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export const NotificationList = () => {
  const { notificationList, paginationQuery, deleteNotification, toggleStatus, onChangePagination } = useUserNotificationContext();
  const { t } = useTranslation();
  const nt = (title: string, params: any) => t(`notification.${title}`, { ...params, interpolation: { escapeValue: false } }).toString();

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={notificationList?.data}
        renderItem={(item, index) => (
          <List.Item className={item.isReaded ? '' : 'bg-blue-50'}>
            <List.Item.Meta
              className="p-2"
              title={<div className="flex justify-start align-middle">{nt(item.title.label, item.title.params)}</div>}
              description={
                <div className="flex justify-between">
                  {item.description?.label && <div>{nt(item.description?.label, item.description?.params)}</div>}

                  <div className="flex justify-end">
                    <Tooltip title="Sil">
                      <Popconfirm title="Emin misiniz?" onConfirm={() => deleteNotification(item.id)}>
                        <div className="cursor-pointer ">
                          <DeleteTwoTone className="text-sm text-red-400" />
                        </div>
                      </Popconfirm>
                    </Tooltip>

                    <Tooltip title={item.isReaded ? 'Okunmadı olarak işaretle' : 'Okundu olarak işaretle'}>
                      <div className="cursor-pointer px-3" onClick={() => toggleStatus(item.id)}>
                        <Badge status={item.isReaded ? 'default' : 'processing'} />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />

      {notificationList?.count != 0 && (
        <Pagination
          className="text-center"
          size="small"
          total={notificationList?.count}
          current={notificationList?.page}
          pageSize={notificationList?.limit}
          onChange={(page) => onChangePagination({ ...paginationQuery, page })}
        />
      )}
    </>
  );
};
