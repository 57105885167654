import { MinusCircleOutlined, PlusCircleOutlined, QuestionOutlined, SendOutlined } from "@ant-design/icons";
import { AvatarFromName } from "@shared/components/AvatarFromName";
import { ExerciseChatModel, ExerciseDefinitionInteractionModel, ExerciseDefinitionModel, ExerciseMemberInfoModel } from "@shared/models";
import { uuid } from "@shared/utils";
import { Button, Checkbox, Dropdown, Form, Input, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { DialogMessageBox } from "./DialogMessageBox";
import { InteractionSelectionComponent } from "../../InteractionSelectionComponent";
import { InteractionFillingComponent } from "../../InteractionFillingComponent";
import { InteractionPronounciationComponent } from "../../InteractionPronounciationComponent";

type CreateDialogBaseProps = {
    item: ExerciseDefinitionModel,
    onSave: (item: ExerciseDefinitionModel) => void,
    onChange: (item: ExerciseDefinitionModel) => void,
    isPreview?: boolean
}

enum QuestionType {
    SELECT = "SELECT",
    FILLING = "FILLING",
    PRONOUNCIATION = "PRONOUNCIATION",
}
export const CreateDialogBase = ({ item, onSave, onChange, isPreview = false }: CreateDialogBaseProps) => {

    const [exercise, setExercise] = useState<ExerciseDefinitionModel>(item);

    const [interaction, setInteraction] = useState<ExerciseDefinitionInteractionModel>({});

    const [newMemeber, setNewMember] = useState<ExerciseMemberInfoModel>({});
    const [selectMemberId, setSelectMemberId] = useState<string>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newChatItem, setNewChatItem] = useState<ExerciseChatModel>({});
    const [questionType, setQuestionType] = useState<QuestionType>();
    const [isMainMemberSelected, setIsMainMemberSelected] = useState<boolean>(false);


    useEffect(() => {
        if (item) {
            setExercise(item);
            if (item.content && item.content?.conversation && item.content.conversation.members) {
                const mainMember = Object.keys(item.content.conversation.members).find((id) => {
                    return item.content?.conversation?.members![id]?.isMainMember;
                });
                if (mainMember) {
                    setIsMainMemberSelected(true);
                } else {
                    setIsMainMemberSelected(false);
                }
            }
            setSelectMemberId(undefined);
        }
    }, [item]);


    const send = () => {
        onSave(exercise);
    }

    const addMember = () => {
        onChange({
            ...exercise,
            content: {
                ...exercise.content,
                conversation: {
                    ...exercise.content?.conversation,
                    members: {
                        ...exercise.content?.conversation?.members,
                        [uuid()]: newMemeber
                    },
                }
            }
        });
        setNewMember({ name: "", isMainMember: false });
    }

    const removeMember = (id: string) => {
        const members = exercise.content?.conversation?.members;
        if (members) {
            delete members[id];
        }
        onChange({
            ...exercise,
            content: {
                ...exercise.content,
                conversation: {
                    ...exercise.content?.conversation,
                    members
                }
            }
        });
    }

    const addConversationText = () => {
        if (newChatItem && newChatItem.text && newChatItem.text != '') {
            onChange({
                ...exercise,
                content: {
                    ...exercise.content,
                    conversation: {
                        ...exercise.content?.conversation,
                        chat: [
                            ...(exercise.content?.conversation?.chat ? exercise.content?.conversation?.chat : []),
                            {
                                ...newChatItem,
                                id: uuid(),
                                who: selectMemberId,
                            }
                        ]
                    }
                }
            });
        }
        setNewChatItem({});
    }

    const onRemoveChat = (chat, index) => {
        const newChats = exercise.content?.conversation?.chat?.filter((c) => c.id != chat.id);
        onChange({
            ...exercise,
            content: {
                ...exercise.content,
                conversation: {
                    ...exercise.content?.conversation,
                    chat: [
                        ...(newChats ?? [])
                    ]
                }
            }
        });
    }

    const addInteraction = (interaction: ExerciseDefinitionInteractionModel) => {
        setIsModalOpen(false);
        onChange({
            ...exercise,

        })
        setInteraction({});

        onChange({
            ...exercise,
            content: {
                ...exercise.content,
                conversation: {
                    ...exercise.content?.conversation,
                    chat: [
                        ...(exercise.content?.conversation?.chat ? exercise.content?.conversation?.chat : []),
                        {

                            id: uuid(),
                            who: selectMemberId,
                            interaction: interaction,
                            text:interaction.textSpeech ? interaction.textSpeech![0] :undefined,
                        }
                    ]
                }
            }
        });
    }

    const onSetQuestionType = (type: QuestionType) => {
        setQuestionType(type);
        setNewChatItem({});
        setIsModalOpen(true);
    }


    return (
        <>
            <div className="p-5 h-full">
                <div className="flex justify-end">
                    <Button type="primary" size="small" onClick={send}>{item.id ? "Güncelle" : "Ekle"}</Button>
                </div>
                <div className="grid grid-cols-2 h-full gap-2">
                    <div className="flex flex-col">
                        <Form layout="vertical">
                            <div className="flex flex-row gap-x-2">
                                <Form.Item label={"Konuşmacı adı:"}>
                                    <Input value={newMemeber.name} onChange={(e) => { setNewMember({ ...newMemeber, name: e.target.value }) }} />
                                </Form.Item>
                                <Form.Item label={"Ana konuşmacı:"}>
                                    <Checkbox disabled={isMainMemberSelected} checked={newMemeber.isMainMember} onChange={(v) => { setNewMember({ ...newMemeber, isMainMember: v.target.checked }) }} />
                                </Form.Item>
                                <Form.Item label={" "}>
                                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={addMember} />
                                </Form.Item>
                            </div>
                        </Form>
                        <div>
                            {exercise.content?.conversation?.members && Object.keys(exercise.content?.conversation?.members!).map((id) => (
                                <div key={id} className="border border-dashed border-mid-gray rounded mb-1 p-2">
                                    <div className="grid grid-cols-3 gap-x-2 items-center" >
                                        <p>{exercise.content?.conversation?.members![id]?.name}</p>
                                        <p>{exercise.content?.conversation?.members![id]?.isMainMember ? ("Ana Konuşmacı") : ("Konuşmacı")}</p>
                                        <Button onClick={() => { removeMember(id) }} icon={<MinusCircleOutlined />} />
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col min-h-max p-1 bg-white justify-around">
                        <div className="h-5/6">
                            <DialogMessageBox conversation={exercise.content?.conversation && Object.keys(exercise.content?.conversation!).length > 0 ? exercise.content?.conversation : {}} isPreview={isPreview} onRemove={onRemoveChat} />
                        </div>
                        <div className={"flex flex-row gap-x-2"}>

                            <Dropdown
                                trigger={['click']}
                                arrow={false}
                                disabled={!isMainMemberSelected}

                                menu={{
                                    items: exercise.content?.conversation?.members ? Object.keys(exercise.content?.conversation?.members!).map((id) => ({
                                        label: (
                                            exercise.content?.conversation?.members![id]?.name
                                        ),
                                        key: id,
                                        onClick: () => {
                                            setSelectMemberId(id);
                                        }

                                    })) : [],
                                    selectable: true,
                                }} placement="top">

                                <a onClick={(e) => e.preventDefault()} className={"!p-0 !m-0"} style={{ display: 'inline-block' }}>
                                    <AvatarFromName title={selectMemberId ? exercise.content?.conversation?.members![selectMemberId]?.name! : "L"} subtitle="" />
                                </a>
                            </Dropdown>

                            <Space.Compact className="w-full" >
                                <Input disabled={selectMemberId == undefined} value={newChatItem.text} onChange={(e) => {
                                    setNewChatItem({
                                        ...newChatItem,
                                        text: e.target.value,
                                    });

                                }} onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        addConversationText();
                                    }
                                }} />
                                <Button type="primary" disabled={selectMemberId == undefined} icon={<SendOutlined />} onClick={addConversationText} />
                            </Space.Compact>
                            {
                                (selectMemberId != undefined && exercise.content?.conversation?.members![selectMemberId!]!.isMainMember) ? (
                                    <Dropdown
                                        disabled={selectMemberId == undefined}
                                        trigger={['click']}
                                        arrow={false}
                                        menu={{
                                            items: [
                                                { label: "Seçenekli", key: QuestionType.SELECT, onClick: () => { onSetQuestionType(QuestionType.SELECT); } }, 
                                                { label: "Boşluk doldurma", key: QuestionType.FILLING, onClick: () => { onSetQuestionType(QuestionType.FILLING); }, },
                                                { label: "Telaffuz", key: QuestionType.PRONOUNCIATION, onClick: () => { onSetQuestionType(QuestionType.PRONOUNCIATION); }, },
                                            ],
                                        }} placement="top">

                                        <a onClick={(e) => e.preventDefault()} className={"text-center pt-1"} style={{ display: 'inline-block' }}>
                                            <QuestionOutlined style={{ fontSize: '150%' }} />
                                        </a>
                                    </Dropdown>
                                ) : (<></>)
                            }

                        </div>
                    </div>
                </div>
            </div>
            <Modal title={selectMemberId == undefined ? "Lütfen konuşmacı seçini" : (exercise.content?.conversation?.members![selectMemberId]!.name + " için sıradaki soruyu oluşturunuz")}
                open={isModalOpen}
                onCancel={() => { setIsModalOpen(false); setQuestionType(undefined); }}
                onOk={() => { setIsModalOpen(false); }}
                footer={(<></>)}
            >
                <div>

                    {
                        questionType == QuestionType.SELECT ? (
                            <div>
                                <h4>Seçenekli Soru Tipi</h4>
                                <InteractionSelectionComponent value={interaction} onChange={(i) => { addInteraction(i); }} />
                            </div>
                        ) : (<></>)
                    }
                    {
                        questionType == QuestionType.FILLING ? (
                            <div>
                                <h4>Boşluk doldurma</h4>
                                <InteractionFillingComponent value={interaction} onChange={(i) => { addInteraction(i); }} />
                            </div>
                        ) : (<></>)
                    }{
                        questionType == QuestionType.PRONOUNCIATION ? (
                            <div>
                                <h4>Telaffuz</h4>
                                <InteractionPronounciationComponent value={interaction} onChange={(i) => { addInteraction(i); }} />
                            </div>
                        ) : (<></>)
                    }
                </div>
            </Modal>
        </>
    )
}