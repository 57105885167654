import { FullscreenOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Calendar as AntdCalendar, Modal, Tag, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ReactElement, useState } from 'react';

export type CalendarModel = {
  id: string;
  date: Date;
  title: string;
  description: string;
  isHoliday: boolean;
  type?: any;
  status?: any;
};
export type CalendarProps<T extends CalendarModel> = {
  title?: string | ReactElement;
  currentDate?: Dayjs;
  events: CalendarModel[] | undefined;
  minimized?: ReactElement;
  cellRenderer?: (dayEvents: T[]) => ReactElement;
  onDateChange?: (date: Dayjs) => void;
};

const defaultMinimizedCalendarHeader = (value: Dayjs, onChange: (v: Dayjs) => void, onExpand: () => void) => {
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(value || dayjs());

  const handlePrevMonth = () => {
    const newValue = value.clone().subtract(1, 'month');
    onChange(newValue);
    setCurrentMonth(newValue);
  };

  const handleNextMonth = () => {
    const newValue = value.clone().add(1, 'month');
    onChange(newValue);
    setCurrentMonth(newValue);
  };

  return (
    <div className="flex justify-between items-center p-2">
      <LeftOutlined className=" cursor-pointer" onClick={handlePrevMonth} />
      <span className=" font-bold">{currentMonth.format('MMMM YYYY')}</span>
      <div className="flex gap-x-2">
        <RightOutlined className="cursor-pointer" onClick={handleNextMonth} />
        <FullscreenOutlined className="cursor-pointer" onClick={onExpand} />
      </div>
    </div>
  );
};

const defaultExpandedCalendarHeader = (value: Dayjs, onChange: (v: Dayjs) => void) => {
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(value || dayjs());

  const handlePrevMonth = () => {
    const newValue = value.clone().subtract(1, 'month');
    onChange(newValue);
    setCurrentMonth(newValue);
  };

  const handleNextMonth = () => {
    const newValue = value.clone().add(1, 'month');
    onChange(newValue);
    setCurrentMonth(newValue);
  };

  return (
    <div className="flex justify-center gap-5 items-center p-2 text-xl">
      <LeftOutlined className=" cursor-pointer" onClick={handlePrevMonth} />
      <span className=" font-bold">{currentMonth.format('MMMM YYYY')}</span>
      <div className="flex gap-x-2">
        <RightOutlined className="cursor-pointer" onClick={handleNextMonth} />
      </div>
    </div>
  );
};

const defaultMinimizedCellRenderer = (events: CalendarModel[]) => {
  return (date: Dayjs) => {
    const currentDate = date.toDate();
    const event = events?.find((event) => dayjs(event.date).isSame(currentDate, 'day'));

    //Eğer o tarihte event varsa borderlı şekilde gösteriyoruz
    if (event && dayjs(event.date).isSame(date, 'day')) {
      return (
        <div className="flex h-full justify-center items-center border-solid rounded-[4px] border-blue-400 ml-1">
          <Tooltip title={event.title}>
            <span className="font-bold">{date.date()}</span>
          </Tooltip>
        </div>
      );
    }

    // O tarihte bir event yoksa normal gösteriyoruz.
    return <div className="flex justify-center items-center h-full">{date.date()}</div>;
  };
};

const defaultCellRenderer = (events: CalendarModel[], color: string = 'white') => {
  return (value) => {
    const currentDate = value.toDate();
    const dayEvents = events.filter((event) => dayjs(event.date).isSame(currentDate, 'day'));

    return (
      <div>
        {dayEvents?.map((event, index) => (
          <div className="flex items-center" key={event.id}>
            <Tooltip title={event.title}>
              <Tag className="w-11/12 whitespace-nowrap overflow-hidden text-ellipsis" color={color}>
                {event.title}
              </Tag>
            </Tooltip>
          </div>
        ))}
      </div>
    );
  };
};

//
const CalendarDefaultMinimized = (props: CalendarProps<CalendarModel>, onExpand: () => void) => {
  const events = props.events || [];

  return (
    <>
      <AntdCalendar
        className="mt-2"
        fullscreen={false}
        value={props.currentDate}
        headerRender={({ value, onChange }) =>
          defaultMinimizedCalendarHeader(
            value,
            (v) => {
              onChange(v);
              props.onDateChange?.(v);
            },
            onExpand
          )
        }
        fullCellRender={defaultMinimizedCellRenderer(events)}
      />
    </>
  );
};

export const Calendar = (props: CalendarProps<any>) => {
  const minimized = props.minimized;
  const events = props.events || [];

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(props.currentDate || dayjs());

  const expand = () => setIsExpanded(true);
  return (
    <>
      {minimized ? <span onClick={expand}>{minimized}</span> : CalendarDefaultMinimized({ ...props, events, currentDate: currentMonth }, expand)}

      <Modal
        className="select-none"
        title={<span className="font-bold text-xl">{props.title}</span>}
        open={isExpanded}
        footer={null}
        onCancel={() => setIsExpanded(false)}
        width={1100}
      >
        <AntdCalendar
          cellRender={
            props.cellRenderer
              ? (currentDate) => {
                  const dayEvents = events?.filter((event) => dayjs(event.date).isSame(currentDate, 'day'));
                  return props.cellRenderer!(dayEvents);
                }
              : defaultCellRenderer(events)
          }
          headerRender={({ value, onChange }) =>
            defaultExpandedCalendarHeader(value, (v) => {
              setCurrentMonth(v);
              props.onDateChange?.(v);
            })
          }
          value={currentMonth}
          mode="month"
        />
      </Modal>
    </>
  );
};
