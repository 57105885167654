import { VideoPlayer } from "@shared/components/curriculum/VideoPlayer";
import { CourseTopicPageModel, MediaModel } from "@shared/models";
import { useEffect, useState } from "react";
import { TextPage } from "../text_page";
import { CdnScope } from "@shared/components/curriculum/FileUpload";

type VideoPageProps = {
    page: CourseTopicPageModel;
    cdnScope: CdnScope;
}

export const VideoPage = ({ page, cdnScope }: VideoPageProps) => {
    
    return (
        <div className={page.content?.text && page.content?.text!="<p><br></p>" ? "grid grid-cols-2 space-x-2 items-center" : "flex justify-center h-full w-full"}>
            <VideoPlayer video={page.content?.video ?? {}} isInit={false} onChange={() => { }} cdnScope={cdnScope} />
            {page.content?.text && page.content?.text != "<p><br></p>" && <TextPage text={page.content?.text} />}
        </div>
    );
}