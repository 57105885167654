import { ImageView } from '@shared/components';
import { useWindowSize } from '@shared/hooks';
import { ChoiceAnswerResponse, CourseTopicModel, ExerciseAnswerRequestModel, ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from '@shared/models';
import { Button } from 'antd';
import { useEffect, useState } from 'react';

type ExerciseProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;
  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  answerResponseModel?: ChoiceAnswerResponse;
};
export const VisualSingleSelect = ({ content, interaction, isPreview = false, isInClass, answerRequestModel, onChangeAnswerRequestModel, answerResponseModel }: ExerciseProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isImageChoice, setIsImageChoice] = useState<boolean>(false);
  const { lg } = useWindowSize();

  useEffect(() => {
    if (interaction) {
      const r = interaction.choices?.filter((c) => c.media && !c.title);
      if (r && r.length > 0) {
        setIsImageChoice(true);
      } else {
        setIsImageChoice(false);
      }
      setIsLoading(false);
    }
  }, [interaction]);

  return (
    <>
      {isInClass ? (
        <div className="flex flex-row items-center h-full bg-white w-full pt-10 pb-10">
          {!isLoading && (
            <div className={isImageChoice ? 'h-full w-3/6 ' : 'border border-double border-mid-gray rounded m-2 h-full w-3/6 text-center'}>
              {isImageChoice ? (
                <div className="h-full w-full grid grid-cols-2 gap-2">
                  {interaction.choices?.map((c) => {
                    return (
                      <div
                        key={c.id}
                        className={
                          answerResponseModel
                            ? answerRequestModel?.choiceIds?.includes(c.id!)
                              ? answerResponseModel.corrections?.find((corrections) => corrections.choiceId == c.id)?.isCorrect
                                ? 'bg-[#F6FFED] border-1 border-solid border-[#52C41A] flex items-center content-center justify-center min-h-max min-w-max '
                                : 'bg-[#FFF1F0] border-1 border-solid border-[#FF4D4F]  flex items-center content-center justify-center min-h-max min-w-max '
                              : ' border-1 border-solid flex items-center content-center justify-center min-h-max min-w-max cursor-pointer  '
                            : answerRequestModel?.choiceIds?.includes(c.id!)
                            ? ' border-[#1677FF]  border-1 border-solid  flex items-center content-center justify-center min-h-max min-w-max cursor-pointer '
                            : ' border-1 border-solid flex items-center content-center justify-center min-h-max min-w-max cursor-pointer '
                        }
                        onClick={() => {
                          if (onChangeAnswerRequestModel) {
                            onChangeAnswerRequestModel({
                              ...answerRequestModel,
                              choiceIds: [c.id!],
                            });
                          }
                        }}
                      >
                        <ImageView image={c.media ? c.media : {}} height="100%" width="100%" className={'object-scale-down'} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <ImageView image={content?.image ? content.image : {}} height="100%" width="100%" className={'object-scale-down'} />
              )}
            </div>
          )}
          <div className="flex flex-col w-3/6 justify-around items-center h-full">
            <div className="items-center flex flex-col  m-2">
              <p className="p-0 m-0 font-extrabold text-3xl text-center text-custom-purple">
                {isPreview ? (content.content ? content.content?.title : '') : content.content?.title}
              </p>
              <p className="text-xl text-light-purple text-center">
                {isPreview ? (content.content?.description ? content.content.description : '') : content.content?.description}
              </p>
            </div>

            {!isImageChoice && interaction && interaction.choices && (
              <div className="space-x-2 space-y-2 w-full flex flex-col flex-wrap items-center justify-center content-center">
                {interaction.choices ? (
                  interaction.choices.map((c) => (
                    <Button
                      key={c.id}
                      type="default"
                      className={
                        'h-auto items-center justify-center flex flex-wrap' +
                        (answerResponseModel
                          ? answerRequestModel?.choiceIds?.includes(c.id!)
                            ? answerResponseModel?.corrections?.find((corrections) => corrections.choiceId == c.id)?.isCorrect
                              ? 'bg-[#F6FFED] border-2 border-[#52C41A] text-success hover:!text-success hover:!bg-[#F6FFED] hover:!border-[#52C41A]'
                              : 'bg-[#FFF1F0] border-2 border-[#FF4D4F] text-error hover:!text-error hover:!bg-[#FFF1F0] hover:!border-[#FF4D4F]'
                            : ''
                          : answerRequestModel?.choiceIds?.includes(c.id!)
                          ? 'border-[#1677FF] bg-[#E6F4FF] border-2'
                          : '')
                      }
                      onClick={() => {
                        if (onChangeAnswerRequestModel) {
                          onChangeAnswerRequestModel({ ...answerRequestModel, choiceIds: [c.id!] });
                        }
                      }}
                    >
                      <span>{c.title}</span>
                    </Button>
                  ))
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center h-full w-full bg-white  py-3 px-5 relative">
          <div className={isImageChoice ? 'm-2 lg:h-4/6 md:h-3/6' : 'border border-double border-mid-gray rounded h-3/6'}>
            {isImageChoice ? (
              <div className="h-full w-full grid grid-cols-2 gap-1">
                {interaction.choices?.map((c) => {
                  return (
                    <div
                      key={c.id}
                      className={
                        answerResponseModel
                          ? answerRequestModel?.choiceIds?.includes(c.id!)
                            ? answerResponseModel.corrections?.find((corrections) => corrections.choiceId == c.id)?.isCorrect
                              ? 'bg-[#F6FFED] border-2 border-solid border-[#52C41A] flex items-center content-center justify-center lg:min-h-max lg:min-w-max '
                              : 'bg-[#FFF1F0] border-2 border-solid border-[#FF4D4F]  flex items-center content-center justify-center lg:min-h-max lg:min-w-max '
                            : answerResponseModel?.expected?.find((expect) => expect.id === c.id)?.isCorrect
                            ? 'bg-[#F6FFED] border-2 border-solid border-[#52C41A] flex items-center content-center justify-center lg:min-h-max lg:min-w-max '
                            : ' border-2 border-solid border-gray-300 flex items-center content-center justify-center lg:min-h-max lg:min-w-max cursor-pointer  '
                          : answerRequestModel?.choiceIds?.includes(c.id!)
                          ? ' border-[#1677FF]  border-2 border-solid  flex items-center content-center justify-center lg:min-h-max lg:min-w-max cursor-pointer '
                          : ' border-2 border-gray-300 border-solid flex items-center content-center justify-center lg:min-h-max lg:min-w-max  cursor-pointer '
                      }
                      onClick={() => {
                        if (onChangeAnswerRequestModel) {
                          onChangeAnswerRequestModel({
                            ...answerRequestModel,
                            choiceIds: [c.id!],
                          });
                        }
                      }}
                    >
                      <ImageView image={c.media ? c.media : {}} height={lg ? '100%' : '120px'} width={lg ? '100%' : '120px'} className={'object-scale-down'} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <ImageView image={content?.image ? content.image : {}} />
            )}
          </div>
          <div className={isImageChoice ? 'items-center flex flex-col lg:h-2/6 md:3/6 m-2' : 'items-center flex flex-col h-1/6 m-2'}>
            <p className="p-0 m-0 font-extrabold text-xl text-center text-custom-purple">{isPreview ? (content.content ? content.content?.title : '') : content.content?.title}</p>
            <p className="text-xs text-light-purple text-center">{isPreview ? (content.content?.description ? content.content.description : '') : content.content?.description}</p>
          </div>
          {!isImageChoice && interaction && interaction.choices ? (
            <div className="h-2/6 mt-3 text-center space-y-6">
              <div className=" space-y-2 w-full items-center justify-center content-center">
                {interaction.choices ? (
                  interaction.choices.map((c) => (
                    <Button
                      key={c.id}
                      type="default"
                      className={
                        'h-auto items-center justify-center flex flex-wrap w-full whitespace-normal' +
                        (answerResponseModel
                          ? answerRequestModel?.choiceIds?.includes(c.id!)
                            ? answerResponseModel?.corrections?.find((corrections) => corrections.choiceId == c.id)?.isCorrect
                              ? 'bg-[#F6FFED] border border-[#52C41A] text-success hover:!text-success hover:!bg-[#F6FFED] hover:!border-[#52C41A] w-full'
                              : 'bg-[#FFF1F0] border border-[#FF4D4F] text-error hover:!text-error hover:!bg-[#FFF1F0] hover:!border-[#FF4D4F] w-full'
                            : ''
                          : answerRequestModel?.choiceIds?.includes(c.id!)
                          ? 'border-[#1677FF] bg-[#E6F4FF] border w-full'
                          : '')
                      }
                      onClick={() => {
                        if (onChangeAnswerRequestModel) {
                          onChangeAnswerRequestModel({ ...answerRequestModel, choiceIds: [c.id!] });
                        }
                      }}
                    >
                      <span>{c.title}</span>
                    </Button>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
