import { PlusCircleOutlined } from "@ant-design/icons"
import { ExerciseDefinitionInteractionType } from "@shared/constants"
import { ExerciseDefinitionInteractionModel } from "@shared/models"
import { uuid } from "@shared/utils"
import { Button, Checkbox, Form, Input, Radio, notification } from "antd"
import { useCallback, useEffect, useState } from "react"

type InteractionSelectionComponentProp = {
    value:ExerciseDefinitionInteractionModel,
    justOneOption?:ExerciseDefinitionInteractionType.SINGLE_CHOICE | ExerciseDefinitionInteractionType.MULTI_CHOICE,
    onChange: (interaction: ExerciseDefinitionInteractionModel) => void
}

export const InteractionSelectionComponent = ({value,justOneOption, onChange }: InteractionSelectionComponentProp) => {
    const [interaction, setInteraction] = useState<ExerciseDefinitionInteractionModel>(value);
    const [choiceName, setChoiceName] = useState<string>();
    const [selectionType, setSelectionType] = useState<ExerciseDefinitionInteractionType>(ExerciseDefinitionInteractionType.SINGLE_CHOICE);

    useEffect(()=>{
        if(interaction){
        }
    },[interaction]);

    useEffect(()=>{
        if(justOneOption){
            setSelectionType(justOneOption);
        }
    },[
        justOneOption
    ])

    useEffect(()=>{
        setInteraction(value);
    },[value]);


    const sendInteraction = useCallback(()=>{
        const _interaction = {...interaction,type:selectionType};
        if(_interaction.type == ExerciseDefinitionInteractionType.SINGLE_CHOICE){
            const count = _interaction!.choices?.filter((c)=>c.isCorrect).length;
            if(count==1){
             onChange(_interaction);
            }else{
             notification.warning({message:"Lütfen 1 adet doğru cevap seçiniz"});
            }
         }
         if(_interaction.type == ExerciseDefinitionInteractionType.MULTI_CHOICE){
             const count = _interaction!.choices?.filter((c)=>c.isCorrect).length;
             if(count&&count>0){
                 onChange(_interaction);
             }else{
                 notification.warning({message:"Lütfen en az 1 adet doğru cevap seçiniz"});
             }
         }

    },[interaction]);

    useEffect(()=>{
        if(selectionType){
            setInteraction({...interaction,type:selectionType});
        }
    },[selectionType])

    return (
        <div>
            <div className="mb-3">
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    disabled={justOneOption ? true : false}
                    value={selectionType}
                    onChange={(e) => {
                        setSelectionType(e.target.value);
                        
                    }}
                    options={[
                        {id:"7854tughfvd" ,label: 'Tekli seçim', value: ExerciseDefinitionInteractionType.SINGLE_CHOICE },
                        { id:"2563etwdg",label: 'Çoklu seçim', value: ExerciseDefinitionInteractionType.MULTI_CHOICE },
                    ]}
                />
            </div>
            <Form layout="vertical">
                <div className="flex flex-row gap-x-2">
                    <Form.Item  key={"cmkdvkmsdklvmlk"}>
                        <Input placeholder="Seçenegin adı" value={choiceName} onChange={(e) => {
                            setChoiceName(e.target.value);
                        }} />
                    </Form.Item>
                    <Button onClick={() => {
                        if (choiceName) {
                            setInteraction({
                                ...interaction,
                                choices: [...(interaction?.choices ? interaction.choices : []), { id: uuid(), title: choiceName,isCorrect:false }]
                            });
                            setChoiceName("");
                        }
                    }} icon={<PlusCircleOutlined />} />
                </div>
                <div className="grid grid-cols-3 gap-1 ">
                    {
                        interaction?.choices?.map((c) => (
                            <Input key={c.id+"ufsjdnfsdjn"} value={c.title} onChange={(e) => {
                                const index = interaction.choices?.findIndex((t) => t.id == c.id);
                                if (index != undefined && index > -1) {
                                    const _choices = [...(interaction.choices ? interaction.choices : [])];
                                    _choices![index] = {
                                        id: c.id,
                                        isCorrect: c.isCorrect,
                                        title: e.target.value
                                    };
                                    setInteraction({
                                        ...interaction,
                                        choices: _choices
                                    })
                                }
                            }} />
                        ))
                    }

                </div>
                {
                    selectionType == ExerciseDefinitionInteractionType.SINGLE_CHOICE ? (
                        <div>
                            <p>Seçeneklerin hangisi doğru</p>
                            <div className="grid grid-cols-3 gap-1">
                                <Radio.Group
                                    value={interaction?.choices?.find((c)=>c.isCorrect)?.id}
                                    onChange={(e) => {
                                        const _targetId = e.target.id;
                                        if (_targetId) {
                                            const index = interaction?.choices?.findIndex((t) => t.id == _targetId);
                                            if (index != undefined && index > -1 ) {
                                                const _choices = [...(interaction!.choices ? interaction!.choices : [])].map((c)=>({...c , isCorrect:false}));
                                                const _findObj = {
                                                    ..._choices[index]
                                                }
                                                _choices![index] = {
                                                    id: _targetId,
                                                    isCorrect: e.target.checked,
                                                    title: _findObj.title
                                                };
                                                setInteraction({
                                                    ...interaction,
                                                    choices: _choices
                                                })
                                            }
                                        }
                                    }}
                                    options={
                                        interaction?.choices?.map((c) => (
                                            { id: c.id, label: c.title!, value: c.id! }
                                        ))
                                    }
                                />
                            </div>
                        </div>) : (<></>)
                }
                {
                    selectionType == ExerciseDefinitionInteractionType.MULTI_CHOICE ? (
                        <div>
                            <p>Seçeneklerin hangileri doğru</p>
                            <div className="grid grid-cols-3 gap-1">

                                {
                                    interaction?.choices?.map((c) => (
                                    <Checkbox key={c.id} onChange={(e) => {
                                        const index = interaction.choices?.findIndex((t) => t.id == c.id);
                                        if (index != undefined && index > -1) {
                                            const _choices = [...(interaction.choices ? interaction.choices : [])];
                                            _choices![index] = {
                                                id: c.id,
                                                isCorrect: e.target.checked,
                                                title: c.title
                                            };
                                            setInteraction({
                                                ...interaction,
                                                choices: _choices
                                            })
                                        }

                                    }} >{c.title}</Checkbox>))
                                }

                            </div>
                        </div>) : (<></>)
                }
            </Form>
            <div className="text-right mt-2">
                <Button type="primary" onClick={sendInteraction}>Kaydet</Button>
            </div>
        </div>
    )
}