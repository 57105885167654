{
  "app": {
    "hello": "Hello",
    "monday": "Mon",
    "tuesday": "Tue",
    "wednesday": "Wed",
    "thursday": "Thu",
    "friday": "Fri",
    "saturday": "Sat",
    "sunday": "Sun",
    "morning": "M",
    "afternoon": "A",
    "night": "N",
    "midWeek": "MW",
    "weekend": "W",
    "noteType": {
      "COMPLAINT": "Complaint",
      "GENERAL": "General",
      "DEMAND": "Demand",
      "TECHNICAL_FAILURE": "Technical Failure"
    },
    "branchType": {
      "BRANCH": "Branch",
      "FRANCHISE": "Franchise"
    },
    "enrollmentStatus": {
      "INFO": "Info",
      "READY_FOR_PLANNING": "Ready for Planning",
      "STARTED": "Started",
      "DONE": "Done",
      "CANCELED": "Canceled"
    },
    "enrollmentType": {
      "INDIVIDUAL": "Individual",
      "GROUP": "Group",
      "ENTERPRISE": "Enterprise"
    },
    "subPermissions": {
      "teacher": "Teacher",
      "observer": "Observer",
      "student": "Student"
    },
    "classStatus": {
      "NOT_STARTED": "Not Started",
      "STARTED": "Started",
      "COMPLETED": "Completed",
      "CANCELED": "Canceled"
    },
    "preplacementStatus": {
      "SUCCESS": "Success",
      "FAILED": "Failed",
      "CONDITIONAL": "Conditional"
    },
    "classHomeworkStatus": {
      "ACTIVE": "Active",
      "WAITING": "Waiting",
      "EXPIRED": "Expired"
    },
    "demandStatus": {
      "ACTION_REQUIRED": "Action Required",
      "DELAYED_ACTION_REQUIRED": "Delayed Action Required",
      "PLANNING": "Planning",
      "ACTIVE": "Active",
      "SUCCESS": "Success",
      "CONDITIONAL": "Conditional",
      "FROZEN": "Frozen",
      "FAILED": "Failed",
      "CANCELED": "Canceled"
    },
    "planningMeta": {
      "LEVEL_NOT_FIT": "Level Not Fit",
      "CALENDAR_NOT_FIT": "Calendar Not Fit",
      "DATE_RANGE_NOT_FIT": "Date Range Not Fit",
      "MAX_QUOTA_EXCEDED": "Max Quota Exceeded",
      "OPTIMAL_QUOTA_EXCEDED": "Optimal Quota Exceeded"
    },
    "gender": {
      "MALE": "Male",
      "FEMALE": "Female"
    },
    "role": {
      "ADMIN": "Admin",
      "USER": "User"
    }
  },
  "error": {
    "payment.required": "Insufficient credit balance. Please create a credit request",
    "forbidden": "You do not have permission for this action",
    "unauthorized": "Invalid username or password",
    "not.found": "The requested resource was not found",
    "bad.request": "Invalid request",
    "internal.server.error": "An unexpected error occurred",
    "conflict": "A resource with the same properties already exists",
    "argument.out.of.range": "Invalid parameter range",
    "argument.invalid": "Invalid parameter",
    "argument.not.provided": "Parameter not provided",
    "entity.is.using.by.another.entity": "This entity is being used by another entity",
    "account.is.not.active": "Account is not active",
    "account.not.found": "Account not found",
    "account.activation.token.expired": "Account activation token has expired",
    "account.activation.token.required": "Account activation token is required",
    "account.reset.password.token.required": "Password reset token is required",
    "account.reset.password.token.expired": "Password reset token has expired",
    "users.cant.delete.themselves": "Users cannot delete their own accounts",
    "users.cant.update.permissions.themselves": "Users cannot update their own permissions",
    "role.not.found": "Role not found",
    "passwords.dont.match": "Passwords do not match",
    "locale.not.found": "Locale not found",
    "product.not.found": "Product not found",
    "unit.not.found": "Unit not found",
    "unit.name.not.found": "Unit name not found",
    "unit.cant.update": "Unit cannot be updated",
    "topic.not.found": "Topic not found",
    "topic.name.not.found": "Topic name not found",
    "topic.type.not.found": "Topic type not found",
    "page.not.found": "Page not found",
    "page.content.cant.be.empty": "Page content cannot be empty",
    "exercise.not.found": "Exercise not found",
    "exercise.content.not.found": "Exercise content not found",
    "exercise.group.not.found": "Exercise group not found",
    "exercise.group.name.not.found": "Exercise group name not found",
    "invalid.exercise.type": "Invalid exercise type",
    "calendar.entry.not.found": "Calendar entry not found",
    "calendar.entry.name.not.found": "Calendar entry name not found",
    "calendar.entry.date.not.found": "Calendar entry date not found",
    "calendar.entry.time.range.not.found": "Calendar entry time range not found",
    "education.level.not.found": "Education level not found",
    "education.theme.not.found": "Education theme not found",
    "education.type.not.found": "Education type not found",
    "education.field.not.found": "Education field not found",
    "content.version.not.found": "Content version not found",
    "content.version.cant.update": "Content version cannot be updated",
    "content.version.cant.delete": "Content version cannot be deleted",
    "content.version.cant.archive": "Content version cannot be archived",
    "content.version.should.be.reviewed.before.publish": "Content version should be reviewed before publishing",
    "content.version.should.be.published.before.set.as.default": "Content version should be published before setting as default",
    "content.version.update.package.not.found": "Content version update package not found",
    "content.version.name.not.found": "Content version name not found",
    "country.code.not.found": "Country code not found",
    "country.name.not.found": "Country name not found",
    "city.code.not.found": "City code not found",
    "city.name.not.found": "City name not found",
    "address.not.found": "Address not found",
    "postal.code.not.found": "Postal code not found",
    "phone.number.not.found": "Phone number not found",
    "begin.time.not.found": "Begin time not found",
    "end.time.not.found": "End time not found",
    "end.time.should.be.after.begin.time": "End time should be after begin time",
    "hour.is.out.of.range": "Hour is out of range",
    "minute.is.out.of.range": "Minute is out of range",
    "start.date.not.found": "Start date not found",
    "start.date.and.end.date.not.found": "Start date and end date not found",
    "end.date.not.found": "End date not found",
    "start.date.should.be.before.end.date": "Start date should be before end date",
    "owner.not.found": "Owner not found",
    "email.is.already.readed": "Email is already read",
    "email.receiver.not.found": "Email receiver not found",
    "email.not.found": "Email not found",
    "invalid.teacher.score": "Invalid teacher score",
    "invalid.exam.score": "Invalid exam score",
    "invalid.homework.score": "Invalid homework score",
    "invalid.attendance.score": "Invalid attendance score",
    "invalid.attendance.score.threshold": "Invalid attendance score threshold",
    "invalid.fail.score.threshold": "Invalid fail score threshold",
    "invalid.success.score.threshold": "Invalid success score threshold",
    "education.level.success.requirement.not.found": "Education level success requirement not found",
    "brand.product.already.exists": "Brand product already exists",
    "brand.not.found": "Brand not found",
    "brand.user.not.found": "Brand user not found",
    "brand.name.not.found": "Brand name not found",
    "brand.content.version.update.package.already.applied": "Brand content version update package already applied",
    "brand.content.version.update.package.already.applying": "Brand content version update package already applying",
    "branch.not.found": "Branch not found",
    "branch.user.not.found": "Branch user not found",
    "branch.cant.change": "Branch cannot be changed",
    "branch.name.not.found": "Branch name not found",
    "enrollment.not.found": "Enrollment not found",
    "enrollment.type.not.found": "Enrollment type not found",
    "enrollment.type.cant.change": "Enrollment type cannot be changed",
    "enrollment.cant.update": "Enrollment cannot be updated",
    "enrollment.cant.accept.new.student": "Enrollment cannot accept new students",
    "enrollment.cant.accept.new.observer": "Enrollment cannot accept new observers",
    "enrollment.basic.info.not.found": "Enrollment basic info not found",
    "enrollment.status.cant.change": "Enrollment status cannot be changed",
    "observer.not.found": "Observer not found",
    "preplacement.already.exists": "Preplacement already exists",
    "class.planning.not.found": "Class planning not found",
    "class.planning.status.cant.change": "Class planning status cannot be changed",
    "seance.not.found": "Seance not found",
    "seance.name.not.found": "Seance name not found",
    "seance.item.not.found": "Seance item not found",
    "seance.item.duration.should.greater.than.zero": "Seance item duration should be greater than zero",
    "seance.item.time.range.should.be.increasing.order": "Seance item time range should be in increasing order",
    "at.least.one.student.required": "At least one student is required",
    "preplacement.not.found": "Preplacement not found",
    "class.room.not.found": "Class room not found",
    "class.room.name.not.found": "Class room name not found",
    "class.room.quota.not.found": "Class room quota not found",
    "class.room.max.quota.exceeded": "Class room max quota exceeded",
    "class.room.optimal.quota.exceeded": "Class room optimal quota exceeded",
    "class.room.quota.cant.be.higher.than.max.quota": "Class room quota cannot be higher than max quota",
    "class.room.calendar.not.fit": "Class room calendar not fit",
    "teacher.level.not.fit": "Teacher level not fit",
    "demand.status.cant.change": "Demand status cannot be changed",
    "demand.basic.info.not.found": "Demand basic info not found",
    "demand.level.not.fit": "Demand level not fit",
    "demand.not.found": "Demand not found",
    "demand.name.not.found": "Demand name not found",
    "demand.calendar.not.found": "Demand calendar not found",
    "demand.calendar.not.fit": "Demand calendar not fit",
    "demand.is.already.completed": "Demand is already completed",
    "demand.is.already.active": "Demand is already active",
    "demand.is.canceled": "Demand is canceled",
    "demand.level.doesnt.belong.to.branch": "Demand level does not belong to branch",
    "demand.is.not.ready.for.planning": "Demand is not ready for planning",
    "demand.level.not.found": "Demand level not found",
    "teacher.not.found": "Teacher not found",
    "teacher.availability.levels.not.found": "Teacher availability levels not found",
    "teacher.availability.calendar.not.found": "Teacher availability calendar not found",
    "teacher.availability.calendar.not.fit": "Teacher availability calendar not fit",
    "invalid.day.of.week": "Invalid day of week",
    "day.of.weeks.required": "Day of weeks is required",
    "class.not.found": "Class not found",
    "class.is.not.active": "Class is not active",
    "class.name.not.provided": "Class name not provided",
    "class.seance.items.overlaping": "Class seance items over lapping",
    "class.already.started": "Class already started",
    "class.can.not.be.started": "Class cannot be started",
    "class.already.completed": "Class already completed",
    "class.should.be.started.before.completing": "Class should be started before completing",
    "class.students.should.be.evaluated.before.completing": "Class students should be evaluated before completing",
    "class.students.already.evaluated": "Class students already evaluated",
    "teacher.student.evaluation.items.not.found": "Teacher student evaluation items not found",
    "teacher.student.evaluation.not.found": "Teacher student evaluation not found",
    "teacher.student.evaluation.invalid.score": "Teacher student evaluation invalid score",
    "teacher.student.evaluation.item.not.found": "Teacher student evaluation item not found",
    "student.already.exists": "Student already exists",
    "student.not.found": "Student not found",
    "class.calendar.entry.not.found": "Class calendar entry not found",
    "class.calendar.entry.name.is.required": "Class calendar entry name is required",
    "class.calendar.entry.already.started": "Class calendar entry already started",
    "in.class.is.not.available.now": "In class is not available now",
    "class.activity.not.found": "Class activity not found",
    "class.activity.already.completed": "Class activity already completed",
    "attendance.not.found": "Attendance not found",
    "make.attendance.first": "Make attendance first",
    "not.completed.class.activity.exist": "Not completed class activity exist",
    "student.not.active": "Student not active",
    "demand.is.frozen.before": "This record has already been frozen and cannot be frozen again.",
    "enrollment.cant.delete": "Only records in the information stage can be deleted"
  },
  "notification": {
    "you.are.brand.user": "You are now a brand user",
    "you.are.brand.user.description": "You are now a brand user in {{brand}}.",
    "you.are.not.brand.user": "You are no longer a brand user",
    "you.are.not.brand.user.description": "You are no longer a brand user in {{brand}}",
    "you.are.teacher.now": "You have been defined as a teacher",
    "you.are.teacher.now.description": "You have been defined as a teacher in the {{branch}} branch.",
    "you.are.not.teacher.anymore": "You are no longer a teacher",
    "you.are.not.teacher.anymore.description": "You are no longer a teacher in {{branch}}",
    "you.are.branch.user": "You are now a branch user",
    "you.are.branch.user.description": "You are now a branch user in {{branch}}: ({{role}})",
    "branch.user.created": "A new user has been created in your branch",
    "branch.user.created.description": "A new user has been created in the {{branch}} branch: {{account}} ({{role}})",
    "brand.user.created": "A new user has been created in your brand",
    "brand.user.created.description": "A new user has been created in the {{brand}} brand: {{account}} ({{role}})",
    "brand.user.deleted": "A user has been deleted from your brand",
    "brand.user.deleted.description": "A user has been deleted from the {{brand}} brand: {{account}} ({{role}})",
    "you.are.not.brand.user.anymore.description": "You are no longer a brand user in {{brandName}}",
    "you.are.not.branch.user": "You are no longer a branch user",
    "you.are.not.branch.user.anymore.description": "You are no longer a brand user in the {{branchName}} branch",
    "course.repetation.registered": "Your course repetition has been successfully registered",
    "course.repetation.registered.description": "The course you will repeat is '{{level}}'",
    "there.is.new.system.content.version": "A new curriculum has been defined for your brand.",
    "there.is.new.system.content.version.description": "A new curriculum is available for the {{brand}} brand: Curriculum Name: {{contentName}}, Curriculum Version: {{systemVersion}}, Curriculum Description: {{contentDescription}} Course: ({{level}})"
  },
  "firstPage": {
    "1": "Just One Platform for Smart Education Solutions",
    "2": "JOEDTECH",
    "3": "JoedTech integrates various software used in the education sector into one platform, providing a comprehensive solution for language institutes and educational institutions.",
    "4": "Log in to JoedTech",
    "5": "Contact Us",
    "6": "By utilizing advanced technologies like artificial intelligence, automation, and big data analytics, it enhances operational efficiency, improves the student experience, and creates a sustainable education ecosystem. This platform is designed to support digital transformation in education, ensuring seamless interaction across branches and departments.",
    "7": "Customer Management",
    "8": "Optimizes student and parent relationships with an AI-powered CRM system, making customer management more efficient and personalized while helping you easily manage sales processes.",
    "9": "Customizable Education System",
    "10": "Provides institutions with updatable curricula and a wide range of exercise options, delivering student-centered, AI-powered personalized education solutions for an innovative learning experience.",
    "11": "Brand and Branch Management",
    "12": "Define your brand and branches, create your organizational hierarchy, and plan your classes using the smart scheduling tool. Provide unique and dynamic educational content to your students.",
    "13": "Next-Generation Education System",
    "14": "JoedTech allows you to offer institution-specific content, preparing your students with pre-class exercises and helping them reinforce what they've learned with post-class exercises.",
    "15": "Interactive Exercises",
    "16": "Enhances your students' learning process with various exercise types, offering an interactive learning experience with AI-supported validation services.",
    "17": "Fun and Effective Language Learning Experience",
    "18": "Provides students with a fast and lasting experience through exercises tailored to all levels, gamified tasks, and instant feedback.",
    "19": "Multi-Platform Support",
    "20": "Works seamlessly with your personal computers, tablets, or mobile devices.",
    "21": "For support and requests",
    "22": "contact us",
    "23": "First and Last Name",
    "24": "Email",
    "25": "Message",
    "26": "Send",
    "27": "Start managing your educational institution now!"
  }
}
