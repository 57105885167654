import { ExerciseDefinitionInteractionType } from '@shared/constants';
import { ExerciseDefinitionInteractionModel } from '@shared/models';
import { shuffleToArray } from '@shared/utils';
import { Button, Form, Input, Tag } from 'antd';
import { useEffect, useState } from 'react';

type InteractionFillingComponentProp = {
  value: ExerciseDefinitionInteractionModel;
  onChange: (interaction: ExerciseDefinitionInteractionModel) => void;
};
export const InteractionFillingComponent = ({ value, onChange }: InteractionFillingComponentProp) => {
  const [inputText, setInputText] = useState<string>();
  const [interaction, setInteraction] = useState<ExerciseDefinitionInteractionModel>(value);

  const sendInteraction = () => {
    onChange(interaction);
  };

  useEffect(() => {
    setInteraction(value);
  }, [value]);

  return (
    <div>
      <div>
        <Form layout="vertical">
          <Form.Item label={'Karıştırılacak cümleyi yazınız:'}>
            <Input
              value={inputText}
              onChange={(e) => {
                const text = e.target.value.trim();
                setInputText(e.target.value);
                const d = text.split(' ');
                setInteraction({
                  ...interaction,
                  type: ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS,
                  orderItems: [...d.map((o) => [o])],
                });
              }}
            />
          </Form.Item>
          <Form.Item label={'Cümlenin doğru hali:'}>{interaction.orderItems ? interaction.orderItems.join(' ') : ''}</Form.Item>
          {interaction.orderItems ? (
            <Form.Item label={'Cümlenin soru hali:'}>
              <div className="flex flex-row gap-2">
                {shuffleToArray(interaction.orderItems!).map((o, i) => (
                  <Tag key={i} color="default">
                    {o}
                  </Tag>
                ))}
              </div>
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </div>
      <div className="text-right mt-2">
        <Button type="primary" onClick={sendInteraction}>
          Kaydet
        </Button>
      </div>
    </div>
  );
};
