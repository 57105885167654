import { DeleteOutlined } from '@ant-design/icons';
import { AvatarFromName } from '@shared/components';
import { ChoiceAnswerResponse, ExerciseChatModel, ExerciseConversationModel, MediaOrderAnswerResponse, OrderAnswerResponse } from '@shared/models';
import { Button } from 'antd';
import { useEffect, useRef } from 'react';
type DialogMessageBoxProp = {
  conversation: ExerciseConversationModel;
  isPreview?: boolean;
  onRemove?: (chat: ExerciseChatModel, index: number) => void;
  chatIndex?: number;
  selectedSingleChoices?: { [index: number]: string };
  selectedMultiChoices?: { [index: number]: { title: string; id: string }[] };
  fillInTheBlanksAnswers?: { [index: number]: string[] };
  voiceRecognitionResult?: { [index: number]: string };
  onBlankClick?: (blankIndex: number, exerciseIndex: number) => void;
  answerResponseList?: { [index: number]: ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse };
};
export const DialogMessageBox = ({
  conversation,
  isPreview = false,
  onRemove,
  chatIndex,
  selectedSingleChoices,
  selectedMultiChoices,
  fillInTheBlanksAnswers,
  onBlankClick,
  voiceRecognitionResult,
  answerResponseList,
}: DialogMessageBoxProp) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatIndex && dialogRef.current) {
      setTimeout(() => {
        dialogRef.current!.scrollTop = dialogRef.current!.scrollHeight!;
      }, 300);
    }
  }, [chatIndex]);
  return (
    <div className="flex flex-col h-full w-full overflow-auto gap-y-4" ref={dialogRef}>
      {chatIndex != undefined ? (
        <>
          {/* Conservation'ı chatIndex kısmına kadar kısaltıyoruz, chatIndex devam ettikçe konuşma akışı da devam edecek*/}
          {conversation.chat?.slice(0, chatIndex + 1).map((c, index) => {
            const member = conversation.members![c.who!];
            return (
              <div key={c.id} className="exercise-message-container" data-main-member={member?.isMainMember}>
                <div className="exercise-message">
                  <AvatarFromName title={member?.name!} subtitle="" />
                  <div
                    className={`${c.interaction ? 'exercise-message-question' : 'exercise-message-text '} ${
                      voiceRecognitionResult ? (voiceRecognitionResult[index] ? (voiceRecognitionResult[index] == 'TRUE' ? '!border-green-500 ' : '!border-red-500 ') : '') : ''
                    }   
                    ${answerResponseList && answerResponseList[index] ? (answerResponseList[index].success ? '!border-green-500' : '!border-red-500') : ''} `}
                  >
                    {c.interaction ? (
                      c.interaction.textSpeech ? (
                        <span className={answerResponseList ? (answerResponseList[index] ? (answerResponseList?.[index]?.success ? 'text-success' : 'text-error') : '') : ''}>
                          {c.text}
                        </span>
                      ) : c.interaction.orderItems ? (
                        fillInTheBlanksAnswers![index]?.map((word, blankIndex) => (
                          <Button
                            className={
                              answerResponseList
                                ? answerResponseList[index]
                                  ? answerResponseList[index]?.corrections![blankIndex].isCorrect
                                    ? '!border !border-[#70C040] ml-2'
                                    : '!border !border-[#ff4d4e] ml-2'
                                  : 'ml-2'
                                : 'ml-2'
                            }
                            disabled={index !== chatIndex}
                            key={blankIndex}
                            type="default"
                            size="small"
                            onClick={() => onBlankClick!(blankIndex, index)}
                          >
                            {word || '___'} {/* Display either the selected word or an empty blank */}
                          </Button>
                        ))
                      ) : selectedSingleChoices && selectedSingleChoices[index] ? (
                        <span className={answerResponseList ? (answerResponseList[index] ? (answerResponseList?.[index]?.success ? 'text-success' : 'text-error') : '') : ''}>
                          {selectedSingleChoices[index]}
                        </span>
                      ) : selectedMultiChoices && selectedMultiChoices[index] && selectedMultiChoices[index].length > 0 ? (
                        selectedMultiChoices[index].map((multiChoice, multiChoiceIndex) => (
                          <div
                            className={`text-sm ${
                              answerResponseList
                                ? answerResponseList[index]
                                  ? answerResponseList?.[index]?.corrections?.some((correction) => correction.choiceId === multiChoice.id && correction.isCorrect)
                                    ? 'text-success'
                                    : 'text-error'
                                  : ''
                                : ''
                            }`}
                            key={multiChoice.id}
                          >
                            {multiChoice.title}
                          </div>
                        ))
                      ) : (
                        '?'
                      )
                    ) : (
                      c.text
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {conversation.chat?.map((c, index) => {
            const member = conversation.members![c.who!];
            return (
              <div key={c.id} className="exercise-message-container" data-main-member={member?.isMainMember}>
                <div className="exercise-message">
                  <AvatarFromName title={member?.name!} subtitle="" />
                  <div className={c.interaction ? 'exercise-message-question' : 'exercise-message-text'}>
                    {c.interaction ? (c.interaction.textSpeech ? c.text : c.interaction.orderItems ? c.interaction.orderItems[0].map((o) => '__ ') : '?') : c.text}
                  </div>
                  {isPreview && onRemove && (
                    <Button
                      danger
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        onRemove(c, index);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
