import { useFetch } from '@shared/hooks';
import { PaginateModel, TeacherAvailableCalendar, TeacherModel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<PaginateModel<TeacherModel>>('/teacher', { method: 'GET', ...config }),
  getById: (id: string) => request('/teacher/' + id, 'GET'),
  create: (teacher: TeacherModel) => request('/teacher', 'POST', teacher),
  update: (teacher: TeacherModel) => request('/teacher', 'PUT', teacher),
  delete: (id: string) => request('/teacher/' + id, 'DELETE'),
  updateAvailableCalendar: (teacherAvailableCalendar: TeacherAvailableCalendar) => request('/teacher/available-calendar', 'PUT', teacherAvailableCalendar),
  getAvailableCalendar: (teacherId: string) => request('/teacher/' + teacherId + '/available-calendar', 'GET'),
  getUnavailableCalendar: (teacherId: string) => request('/teacher/' + teacherId + '/unavailable-calendar', 'GET'),
  getTeacherLevels: () => request('/teacher/levels', 'GET'),
};
