import { MediaModel } from "@shared/models";
import { useEffect, useRef, useState } from "react";
import * as API from '@shared/api';
import { CdnScope } from "./FileUpload";

type VideoPlayerProps = {
    video: MediaModel;
    isInit: boolean;
    onChange: () => void;
    iconFontSize?: string;
    isPreview?: boolean;
    cdnScope: CdnScope;
}

export const VideoPlayer = ({ video, isInit, onChange, iconFontSize, isPreview, cdnScope }: VideoPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoUrl, setVideoUrl] = useState<string>();
    const [isPlay, setIsPlay] = useState<boolean>(false);
    const restMap = {
        [CdnScope.ADMIN]: API.CDN.obtainDownloadVideoAndSoundUrl,
        [CdnScope.BRAND]: API.CDN.brandObtainDownloadVideoAndSoundUrl,
        [CdnScope.BRANCH]: API.CDN.branchObtainDownloadVideoAndSoundUrl,
    };

    useEffect(() => {
        if (video.resourceUrl) {
            setVideoUrl(video.resourceUrl);
        } else if (video.resourceId) {
            getVideoUrl(video.resourceId);
        }
    }, [video]);

    useEffect(() => {
        if (videoUrl) {
            videoRef.current!.src = videoUrl;
            if (isInit && !isPreview) {
                startToPlay();
            }
        }
    }, [videoUrl]);

    const getVideoUrl = async (id) => {
        try {
            const {
                data: { url },
            } = await restMap[cdnScope](id);
            setVideoUrl(url);
            videoRef.current!.src = url;
        } catch (error) {
            console.log("Error fetching video URL:", error);
         }
    };

    const startToPlay = () => {
        if (isPlay) {
            videoRef.current!.pause();
            setIsPlay(false);
        } else {
            videoRef.current!.load();
            videoRef.current!
                .play()
                .then(() => {
                    setIsPlay(true);
                })
                .catch((e) => {
                    setIsPlay(false);
                })
                .finally(() => {
                });
        }
    };

    return (
        <video height={"100%"} width={"100%"} controls ref={videoRef} />
    );
}