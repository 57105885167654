import { formatPhone, isObjectEmpty } from '@shared/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const defaultDateFormat = 'YYYY/MM/DD';

function reverseFx(input) {
  const output = {};

  function flattenObject(obj, path = '') {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newPath = path ? `${path}.${key}` : key;
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          flattenObject(obj[key], newPath);
        } else {
          output[newPath] = obj[key];
        }
      }
    }
  }

  flattenObject(input);

  return output;
}

export const useForm = <T extends object, Z extends object = {}>(Model: z.AnyZodObject, initialValue: Partial<Z>) => {
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [valids, setValids] = useState<Record<string, boolean>>({});
  const [toucheds, setToucheds] = useState<Record<string, boolean>>({});
  const [selected, setSelected] = useState<Partial<T> | undefined>();
  const [value, setValue] = useState<Partial<T>>(reverseFx(initialValue));

  useEffect(() => {
    const parseResult = Model.safeParse(value);
    setValid(parseResult.success);
    let _errors: Record<string | number, string> = {};
    if (!parseResult.success) {
      _errors = Object.fromEntries(parseResult.error.issues.map(({ path, message }) => [path[0], message]));
    }
    setErrors(_errors);
    setValids(Object.fromEntries(Object.keys(Model.shape).map((key) => [key, Boolean(_errors[key])])));
  }, [value, setValid, setErrors, setValids]);

  const parse = useCallback(() => {
    const output = {};
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        const keys = key.split('.');
        let currentObj = output;
        keys.forEach((nestedKey, index) => {
          if (index === keys.length - 1) {
            currentObj[nestedKey] = value[key];
          } else {
            currentObj[nestedKey] = currentObj[nestedKey] || {};
            currentObj = currentObj[nestedKey];
          }
        });
      }
    }
    const validSchema = Model.safeParse(value) as z.SafeParseReturnType<Partial<T>, Partial<T>>;
    return { ...validSchema, data: output as Z };
  }, [value, setToucheds]);

  const setField = useCallback(
    (fieldName: keyof T) => {
      return (feildValue: unknown) => {
        setValue((_value) => ({ ..._value, [fieldName]: feildValue }));
        window.setTimeout(() => {
          setToucheds((_toucheds) => ({ ..._toucheds, [fieldName]: true }));
        }, 0);
      };
    },
    [setValue, setToucheds]
  );

  const setAllTouched = useCallback(() => {
    setToucheds(Object.fromEntries(Object.keys(Model.shape).map((key) => [key, true])));
  }, [initialValue, setToucheds]);

  const formItem = useCallback(
    (fieldName: keyof T) => {
      const isInvalid = toucheds[fieldName as string] && valids[fieldName as string];
      return {
        help: isInvalid ? errors[fieldName as string] : '',
        validateStatus: isInvalid ? 'error' : ('success' as any),
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const input = useCallback(
    (fieldName: keyof T) => {
      return {
        value: value[fieldName] || ('' as any),
        onChange: (e: any) => setField(fieldName)(e.target.value),
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const inputSwitch = useCallback(
    (fieldName: keyof T) => {
      return {
        checked: !!value[fieldName],
        onChange: (checked: boolean) => setField(fieldName)(checked),
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const inputDate = useCallback(
    (fieldName: keyof T, dateFormat = defaultDateFormat) => {
      return {
        value: value[fieldName as string] ? dayjs(value[fieldName as string], dateFormat) : (undefined as any),
        onChange: (date: Dayjs, dateString: string | string[]) => setField(fieldName)(dateString),
        format: dateFormat,
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const inputPhone = useCallback(
    (phoneCode: keyof T, fieldName) => {
      return {
        value: value[phoneCode] ? (value[phoneCode]!.toString().includes('+') ? value[phoneCode] : '+' + value[phoneCode]) + value[fieldName] : '',
        onChange: (phoneNumber) => {
          if (phoneNumber) {
            const { code, number } = formatPhone(phoneNumber);
            setField(phoneCode)(code ? '+' + code : phoneNumber);
            setField(fieldName)(number ? number : '');
          }
        },
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const inputNumber = useCallback(
    (fieldName: keyof T) => {
      return {
        value: value[fieldName] || ('0' as any),
        onChange: setField(fieldName),
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const inputSelected = useCallback(
    (fieldName: keyof T) => {
      return {
        value: value[fieldName] || ('' as any),
        onChange: (e: any) => setField(fieldName)(e),
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const props = useCallback(
    (fieldName: keyof T) => {
      return {
        value: value[fieldName] || ('' as any),
        errorMessage: errors[fieldName as string],
        onChange: setField(fieldName),
        isInvalid: toucheds[fieldName as string] && valids[fieldName as string],
      };
    },
    [value, valids, errors, toucheds, setField]
  );

  const reset = useCallback(() => {
    setValue(reverseFx(initialValue));
    setToucheds({});
    setSelected(undefined);
  }, [setValue, setToucheds, setSelected]);

  const fill = useCallback(
    (newValue: Partial<T>) => {
      if (newValue && !isObjectEmpty(newValue)) {
        setSelected(newValue);
        setValue({ ...newValue });
      }
    },
    [setSelected, setValue]
  );

  const patch = useCallback(
    (patchValue: Partial<T>) => {
      if (patchValue) {
        setValue({ ...value, ...patchValue });
      }
    },
    [value, setValue]
  );

  const setInitialValue = (v: Partial<Z>) => {
    setValue(reverseFx(v));
  };

  const validateField = useCallback(
    (fieldName: keyof T) => {
      const fieldSchema = Model.shape[fieldName];
      const result = fieldSchema.safeParse(value[fieldName]);

      if (result.success) {
        return { valid: true, data: result.data };
      } else {
        return { valid: false, error: result.error.issues[0].message };
      }
    },
    [value]
  );

  const validationErrors = useMemo(() => {
    return Object.fromEntries(
      Object.keys(Model.shape)
        .filter((key) => toucheds[key])
        .map((key) => [key, errors[key]])
    );
  }, [valid, errors, toucheds]);

  const touchedAndInvalid = useMemo(() => {
    const someTouched = Object.entries(toucheds).some(Boolean);
    return !valid && someTouched;
  }, [valid, toucheds]);

  return {
    value,
    valid,
    errors,
    validationErrors,
    validateField,
    toucheds,
    valids,
    props,
    parse,
    setInitialValue,
    setValue,
    setField,
    setAllTouched,
    reset,
    fill,
    patch,
    selected,
    touchedAndInvalid,
    formItem,
    input,
    inputDate,
    inputNumber,
    inputSelected,
    inputPhone,
    inputSwitch,
  };
};
