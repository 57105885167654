import {
  BookOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  ImportOutlined,
  MoreOutlined,
  StopOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useUserContext } from '@shared/contexts';
import { Dropdown, MenuProps, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { LiaWalletSolid } from 'react-icons/lia';

//TODO: Burası düzenlenmeli

type ActionDropdownType = {
  data: any;
  items?: MenuProps['items'];
  isEdit?: boolean;
  isDelete?: boolean;
  isCreateReminder?: boolean;
  isGoHomework?: boolean;
  isEditUser?: boolean;
  isViewAssignment?: boolean;
  isMarkAsPaid?: boolean;
  isApprove?: boolean;
  isReject?: boolean;
  isEditAssignment?: boolean;
  isDeleteAssignment?: boolean;
  isAssignHomeworkClasses?: boolean;
  isEditDraft?: boolean;
  isDeleteDraft?: boolean;
  isPostpone?: boolean;
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
  onCreateReminder?: (data: any) => void;
  onGoHomework?: (data: any) => void;
  onEditUser?: (data: any) => void;
  onViewAssignment?: (data: any) => void;
  onMarkAsPaid?: (data: any) => void;
  onApprove?: (data: any) => void;
  onReject?: (data: any) => void;
  onEditAssignment?: (data: any) => void;
  onDeleteAssignment?: (data: any) => void;
  onAssignHomeworkClasses?: (data: any) => void;
  onPostpone?: (data: any) => void;
  onEditDraft?: (data: any) => void;
  onDeleteDraft?: (data: any) => void;
  placement?: 'bottom' | 'bottomLeft' | 'bottomRight' | 'top' | 'topLeft' | 'topRight';
  disabled?: boolean;
  iconColor?: string;
};
export const ActionDropdown = ({
  data,
  items = [],
  isDelete = true,
  isEdit = true,
  isCreateReminder = false,
  isGoHomework = false,
  isEditUser = false,
  isViewAssignment = false,
  isMarkAsPaid = false,
  isApprove = false,
  isReject = false,
  isEditAssignment = false,
  isDeleteAssignment = false,
  isAssignHomeworkClasses = false,
  isEditDraft = false,
  isDeleteDraft = false,
  isPostpone = false,
  onDelete,
  onEdit,
  onCreateReminder,
  onGoHomework,
  onEditUser,
  onViewAssignment,
  onMarkAsPaid,
  onApprove,
  onReject,
  onEditAssignment,
  onDeleteAssignment,
  onAssignHomeworkClasses,
  onEditDraft,
  onDeleteDraft,
  onPostpone,
  placement,
  disabled = false,
  iconColor,
}: ActionDropdownType) => {
  const [menu, setMenu] = useState<MenuProps['items']>([]);
  //const menu: MenuProps['items'] = [];

  const { user } = useUserContext();

  useEffect(() => {
    const _menu: MenuProps['items'] = [];
    if (items.length > 0) {
      items.forEach((item, i) => {
        _menu.push({ ...item, key: i + 'key' });
      });
    }
    if (isEdit) {
      _menu.push({
        key: '1',
        icon: <EditOutlined />,
        label: <a onClick={() => (onEdit ? onEdit!(data) : null)}>Düzenle</a>,
      });
    }
    if (isDelete && data.email !== user.email) {
      _menu.push({
        key: '2',
        icon: <DeleteOutlined />,
        label: (
          <Popconfirm title="Silinecek" description="Silmek istediğinize emin misiniz?" onConfirm={() => (onDelete ? onDelete!(data) : null)} okText="Evet" cancelText="İptal">
            <a>Sil</a>
          </Popconfirm>
        ),
        danger: true,
      });
    }
    if (isCreateReminder) {
      _menu.push({
        key: '3',
        icon: <CalendarOutlined />,
        label: <a onClick={() => (onCreateReminder ? onCreateReminder!(data) : null)}>Hatırlatıcı Oluştur</a>,
      });
    }
    if (isGoHomework) {
      _menu.push({
        key: '4',
        icon: <BookOutlined />,
        label: <a onClick={() => (onGoHomework ? onGoHomework!(data) : null)}>Ödeve Git</a>,
      });
    }
    if (isEditUser) {
      _menu.push({
        key: '4',
        icon: <EditOutlined />,
        label: <a onClick={() => (onEditUser ? onEditUser!(data) : null)}>Kullanıcı Ekle/Düzenle</a>,
      });
    }
    if (isViewAssignment) {
      _menu.push({
        key: 'isViewAssignment',
        icon: <ImportOutlined />,
        label: <a onClick={() => (onViewAssignment ? onViewAssignment!(data) : null)}>Ödevi görüntüle</a>,
      });
    }

    if (isMarkAsPaid) {
      _menu.push({
        key: 'isMarkAsPaid',
        icon: <LiaWalletSolid size={15} />,
        label: (
          <Popconfirm title="Emin misiniz?" description="Bu işlem Geri Alınamaz?" onConfirm={() => (onMarkAsPaid ? onMarkAsPaid!(data) : null)} okText="Evet" cancelText="İptal">
            <a>Ödendi Olarak İşaretle</a>
          </Popconfirm>
        ),
      });
    }

    if (isApprove) {
      _menu.push({
        key: 'isApprove',
        icon: <CheckCircleOutlined />,
        label: (
          <Popconfirm title="Emin misiniz?" description="Bu işlem Geri Alınamaz?" onConfirm={() => (onApprove ? onApprove!(data) : null)} okText="Evet" cancelText="İptal">
            <a>Onayla</a>
          </Popconfirm>
        ),
      });
    }

    if (isReject) {
      _menu.push({
        key: 'isReject',
        icon: <StopOutlined />,
        label: (
          <Popconfirm title="Emin misiniz?" description="Bu işlem Geri Alınamaz?" onConfirm={() => (onReject ? onReject!(data) : null)} okText="Evet" cancelText="İptal">
            <a>Reddet</a>
          </Popconfirm>
        ),
      });
    }
    if (isEditAssignment) {
      _menu.push({
        key: 'isEditAssignment',
        icon: <EditOutlined />,
        label: <a onClick={() => (onEditAssignment ? onEditAssignment!(data) : null)}>Ödevi düzenle</a>,
      });
    }

    if (isDeleteAssignment) {
      _menu.push({
        key: 'isDeleteAssignment',
        icon: <DeleteOutlined />,
        label: (
          <Popconfirm
            title="Ödevi sil"
            description="Ödevi silmek istediğine emin misin?"
            onConfirm={() => (onDeleteAssignment ? onDeleteAssignment!(data) : null)}
            okText="Evet"
            cancelText="İptal"
          >
            <a>Ödevi Sil</a>
          </Popconfirm>
        ),
        danger: true,
      });
    }
    if (isAssignHomeworkClasses) {
      _menu.push({
        key: 'isAssignHomeworkClasses',
        icon: <FileTextOutlined />,
        label: <a onClick={() => (onAssignHomeworkClasses ? onAssignHomeworkClasses!(data) : null)}>Ödevi sınıflara ata</a>,
      });
    }
    if (isEditDraft) {
      _menu.push({
        key: 'isEditDraft',
        icon: <EditOutlined />,
        label: <a onClick={() => (onEditDraft ? onEditDraft!(data) : null)}>Taslağı düzenle</a>,
      });
    }

    if (isDeleteDraft) {
      _menu.push({
        key: 'isDeleteDraft',
        icon: <UserOutlined />,
        label: (
          <Popconfirm
            title="Taslağı sil"
            description="Taslağı silmek istediğine emin misin?"
            onConfirm={() => (onDeleteDraft ? onDeleteDraft!(data) : null)}
            okText="Evet"
            cancelText="İptal"
          >
            <a>Taslağı sil</a>
          </Popconfirm>
        ),
        danger: true,
      });
    }
    if (isPostpone) {
      _menu.push({
        key: 'isPostpone',
        icon: <UserOutlined />,
        label: (
          <Popconfirm
            title="Taslağı sil"
            description="Taslağı silmek istediğine emin misin?"
            onConfirm={() => (onDeleteDraft ? onDeleteDraft!(data) : null)}
            okText="Evet"
            cancelText="İptal"
          >
            <a>Taslağı sil</a>
          </Popconfirm>
        ),
        danger: true,
      });
    }
    setMenu(_menu);
  }, [
    isDelete,
    isEdit,
    isCreateReminder,
    isGoHomework,
    isEditUser,
    isViewAssignment,
    isMarkAsPaid,
    isApprove,
    isReject,
    isEditAssignment,
    isDeleteAssignment,
    isAssignHomeworkClasses,
    isEditDraft,
    isDeleteDraft,
    isPostpone,
    items.length,
  ]);

  return (
    <>
      {menu && menu.length > 0 && (
        <Dropdown menu={{ items: menu }} trigger={['click']} placement={placement} disabled={disabled}>
          <a onClick={(e) => e.preventDefault()}>
            <MoreOutlined className={iconColor ? `text-[${iconColor}]` : 'text-black'} />
          </a>
        </Dropdown>
      )}
    </>
  );
};
