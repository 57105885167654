import { AudioOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

type PronunciationVerifierProps = {
  texts: string[];
  onVerify: (result: boolean) => void;
  iconFontSize?: string;
};

export const PronunciationVerifier = ({ texts, onVerify, iconFontSize = '3rem' }: PronunciationVerifierProps) => {
  const [commands, setCommands] = useState<any[]>([]);
  const { transcript, listening, browserSupportsSpeechRecognition, isMicrophoneAvailable } = useSpeechRecognition({ commands });

  let timer;

  /*useEffect(() => {
    console.log(transcript);
  }, [transcript]);*/

  useEffect(() => {
    if (listening) {
      timer = setTimeout(() => {
        onVerify(false);
        SpeechRecognition.stopListening();
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [listening]);

  useEffect(() => {
    if (texts.length > 0) {
      setCommands([
        {
          command: texts[0],
          callback: () => {
            onVerify(true);
            SpeechRecognition.stopListening();
          },
          isFuzzyMatch: true,
          fuzzyMatchingThreshold: 0.8,
          //bestMatchOnly: true,
          matchInterim: true,
        },
      ]);
    }
  }, [texts]);

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      alert('Browser does not support speech recognition.');
      console.log('Browser does not support speech recognition.');
    }
    if (!isMicrophoneAvailable) {
      console.log('Microphone is not available.');
    }
  }, [isMicrophoneAvailable, browserSupportsSpeechRecognition]);
  return (
      <Button
        type="text"
        className="!w-max !h-max"
        size="large"
        onClick={() => {
          
          if (listening) {
            SpeechRecognition.stopListening();
          } else {
            SpeechRecognition.startListening({
              continuous: false,
              language: 'en-US',
            });
          }
        }}
        icon={<AudioOutlined style={{ fontSize: iconFontSize, color: listening ? 'blueviolet' : '' }} />}
      />
  );
};
