import { ImageView, SoundPlayer, TextPage } from "@shared/components";
import { CdnScope } from "@shared/components/curriculum/FileUpload";
import { CourseTopicPageModel } from "@shared/models";

type ImagePageProps = {
    page: CourseTopicPageModel,
    cdnScope: CdnScope
}

export const ImagePage = ({ page, cdnScope }: ImagePageProps) => {

    return (
        <div className={page.content?.sound || (page.content?.text && page.content?.text != "<p><br></p>") ? "grid grid-cols-2 space-x-4 h-full w-full" : "flex h-full w-full text-center"}>
            <div className="flex max-h-min min-h-min min-w-full ">
                <ImageView image={page.content?.image ? page.content?.image : {}} height="100%" width="100%" className={"object-scale-down" } isPreview={true}/>
            </div>
            <div className="flex flex-col pt-3 min-h-full w-full">
                {page.content?.sound && (<div className="h-[10%] w-full"><SoundPlayer sound={page.content?.sound} onChange={() => { }} isInit={false} inline={true} cdnScope={cdnScope} /></div>)}
                <div className={page.content?.sound ? "h-[90%] w-full":"h-[100%] w-full"}>
                    {page.content?.text && page.content?.text != "<p><br></p>" && <TextPage text={page.content?.text} />}
                </div>
            </div>
        </div>
    );
}