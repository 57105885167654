import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Role } from '@shared/constants';
import { useBrandContext, useUserContext } from '@shared/contexts';
import { useWindowSize } from '@shared/hooks';
import { menuGroups, pageToMenuItem } from '@shared/utils';
import { Button, Layout, Menu } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { RouteBreadcrumb } from '../RouteBreadcrumb';
import { HeaderDropDown } from './header/HeaderDropDown';
const { Sider, Content } = Layout;

const bc = new BroadcastChannel('auth.channel.joedtech');
export const MainLayout = ({ pages }) => {
  const { sm, md } = useWindowSize();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const { user } = useUserContext();
  const { brand, setBrand } = useBrandContext();

  const location = useLocation();

  const contexts: Record<string, any> = { brand };
  const contextSetters: Record<string, any> = { brand: setBrand };

  useEffect(() => {
    bc.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        window.location.reload();
      }
    };
  }, []);

  const menuItems = pages.map(pageToMenuItem);
  const { userMenuItems, menu } = useMemo(() => {
    /*

if (user?.id) {
      const userMenuItems = menuItems.filter((item) => user.role && item.roles.includes(user.role));
    */
    if (user?.jwt) {
      const userMenuItems = menuItems.filter((item) => item.roles.includes(Role.ADMIN));

      let menu = menuGroups
        .filter((group) => {
          if (group.contextname) {
            return contexts[group.contextname];
          }

          return true;
        })
        .map((group) => ({
          ...group,
          label: typeof group.title === 'string' ? <span>{group.title}</span> : group.title,
          children: userMenuItems.filter((item) => item.group === group.key),
        })) as any[];

      menu = menu.filter((item) => item.children.length > 0);
      menu = [...userMenuItems.filter((item) => item.group === 'flat'), ...menu];
      return { userMenuItems, menu };
    }
    return { userMenuItems: [], menu: [] };
  }, [menuItems, user, brand]);

  const menuSelectedKeys = useMemo(() => {
    const path = location.pathname.replace('/', '');
    const menu = userMenuItems.find((item) => item.key === path);

    if (menu) {
      return [menu.group, menu.key];
    }

    return [];
  }, [location, userMenuItems]);

  useEffect(() => {
    const path = location.pathname.replace('/', '');
    const menu = userMenuItems.find((item) => item.key === path);

    if (menu) {
      Object.entries(contextSetters).map(([key, contextSetter]) => {
        const menuContexts = menu.contexts;
        if (!menuContexts || !menuContexts.includes(key)) {
          contextSetter(undefined);
        }
      });
    }
  }, [location, userMenuItems, brand]);

  return (
    <Layout hasSider>
      <Sider
        width={sm ? 230 : 'calc(100vw - 60px)'}
        collapsed={collapsed}
        breakpoint="md"
        zeroWidthTriggerStyle={{ top: 0 }}
        onCollapse={() => setCollapsed(!collapsed)}
        style={{ backgroundColor: '#FFFFFF', paddingTop: 40 }}
      >
        <div>
          {!collapsed ? (
            <div className="text-lg w-full flex flex-col justify-around items-center py-2 p-4 m-2">
              <span>Just One Tech</span>
            </div>
          ) : (
            <div className="text-lg w-full flex flex-col justify-around items-center py-2 p-4 m-2">
              <span>JT</span>
            </div>
          )}

          <Menu
            mode="inline"
            items={menu}
            onClick={() => {}}
            openKeys={openKeys}
            selectedKeys={menuSelectedKeys}
            onOpenChange={(keys) => {
              setOpenKeys([keys[keys.length - 1]]);
            }}
            style={{ backgroundColor: '#FFFFFF' }}
          />

          {/* {<PoweredByTag collapsed={collapsed} />} */}
        </div>
      </Sider>

      <Layout style={{ height: '100svh' }}>
        <Header style={{ padding: 0, backgroundColor: '#FFFFFF' }}>
          <div className="grid grid-cols-2">
            {collapsed ? (
              <div className="pl-2">
                <Button icon={<MenuFoldOutlined />} size={'small'} onClick={() => setCollapsed(!collapsed)} />
              </div>
            ) : (
              <div className="pl-2">
                <Button icon={<MenuUnfoldOutlined />} size={'small'} onClick={() => setCollapsed(!collapsed)} />
              </div>
            )}

            <div className="flex justify-end pr-10 pt-4">
              <HeaderDropDown />
            </div>
          </div>
        </Header>
        <RouteBreadcrumb items={userMenuItems} />
        <Content style={{ margin: '12px 16px 16px', overflow: 'auto', backgroundColor: '#F5F5F5' }}>
          <Routes>
            {pages.map(([key, Comp]) => (
              <Route
                key={key}
                path={Comp.path}
                element={<Comp />}
                handle={(a, b, c) => {
                }}
              />
            ))}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};
