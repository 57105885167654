import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { ProfileSetting } from '@shared/components/ProfileSetting';
import { Role } from '@shared/constants';
import { notification, TabsProps } from 'antd';
import { Card, Tabs } from 'antd';
import * as API from '@shared/api';

export const AdminSetting = () => {
  const [profile, isProfileLoading, getProfile] = API.ACCOUNT.retriveProfile({ init: true });

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Profil ayarları',
      children: <ProfileSetting profile={profile!} updateProfile={(formValue) => updateProfile(formValue)} />,
      icon: <UserOutlined />,
    },
    // {
    //     key: '2',
    //     label: "Bildirim ayarları",
    //     children: <NotificationSetting/>,
    //     icon: <BellOutlined />
    // },
    // {
    //     key: '3',
    //     label: "Parola ayarları",
    //     children: <PasswordSetting/>,
    //     icon: <LockOutlined />
    // }
  ];
  const updateProfile = async (form) => {
    const response = await API.ACCOUNT.updateProfile({
      ...form.value,
      phone: {
        code: form.value['phone.code'],
        number: form.value['phone.number'],
      },
    } as any);

    if (response.ok) {
      notification.success({ message: 'Prfil Bilgileriniz Güncellendi' });
      getProfile();
    }
  };

  return (
    <Card>
      <Tabs items={items} />
    </Card>
  );
};

AdminSetting.path = 'setting';
AdminSetting.title = 'Ayarlar';
AdminSetting.group = '';
AdminSetting.roles = [Role.ADMIN];
AdminSetting.icon = <SettingOutlined size={20} />;
