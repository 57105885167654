import { PermissionsModel } from './permissions';

export type LoginUser = Partial<{
  id: string;
  name: string;
  surname: string;
  email: string;
  jwt: string;
  features: {
    brand: {
      [brandId: string]: {
        permissions: PermissionsModel;
      };
    };
    branch: {
      [branchId: string]: BranchPermissionAndSubFeatures;
    };
    isSystemAdmin: boolean;
  };
}>;

export type BranchPermissionAndSubFeatures = {
  permissions: PermissionsModel;
  isStudent: Boolean;
  isTeacher: Boolean;
  isObserver: Boolean;
};
